define("workcompass-components/templates/components/card-dotted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gUmZckyK",
    "block": "{\"symbols\":[\"label\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isExpanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"nav\"],[11,\"class\",\"action-list listbox icolist\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"menuItems\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[28,[\"ember-wc-core ico ico-\",[27,\"camelize\",[[22,1,[]]],null]]]],[9],[1,[22,1,[]],false],[3,\"action\",[[22,0,[]],\"onMenuItemClick\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/card-dotted.hbs"
    }
  });

  _exports.default = _default;
});