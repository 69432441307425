define("organization-settings/models/rating-option-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    draftLabel: _emberData.default.attr('string'),
    publishedLabel: _emberData.default.attr('string'),
    score: _emberData.default.attr('number'),
    draftScore: _emberData.default.attr('number'),
    publishedScore: _emberData.default.attr('number'),
    publishedAt: _emberData.default.attr('date'),
    markedForArchivalAt: _emberData.default.attr('date'),
    archivedAt: _emberData.default.attr('date'),
    planTemplate: _emberData.default.belongsTo('plan-template', {
      inverse: 'ratingOptionTemplates'
    }),
    sectionTemplate: _emberData.default.belongsTo('section-template', {
      inverse: 'ratingOptionTemplates'
    }),
    isDraft: Ember.computed('publishedAt', function () {
      return Boolean(this.get('publishedAt')) === false;
    }),
    isMarkedForArchival: Ember.computed('markedForArchivalAt', function () {
      return Boolean(this.get('markedForArchivalAt'));
    }),
    isArchived: Ember.computed('archivedAt', function () {
      return Boolean(this.get('archivedAt'));
    }),
    revert: function revert() {
      if (!this.get('isArchived')) {
        this.set('draftLabel', this.get('publishedLabel'));
        this.set('draftScore', this.get('publishedScore'));
        this.set('markedForArchivalAt', null);
      }

      return this;
    }
  });

  _exports.default = _default;
});