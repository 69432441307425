define("organization-settings/routes/abstract-router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Service required by most of the routers
    session: Ember.inject.service(),
    store: Ember.inject.service()
  });

  _exports.default = _default;
});