define("ember-wc-360/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('programs', {
      path: '/programs/:id'
    });
  });

  _exports.default = _default;
});