define("design-system/templates/components/textareas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fd8ScaEE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Textareas \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Textareas are multi-line inputs used by users to enter longer text portions. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  In WorkCompass textareas are used in each of the cards on the plan page.\\n  They are also used in each card on the template page where they are disabled.\\n\"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of a textarea. It has the class of wc-textarea,\\n  it is possible to pass in if it should be disabled, placeholder text and a value.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    {{textarea classNames='wc-textarea' disabled=isDisabled placeholder=placeholderText value=commentText}}\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[1,[27,\"textarea\",null,[[\"classNames\",\"disabled\",\"placeholder\",\"value\"],[\"wc-textarea\",[23,[\"isDisabled\"]],[23,[\"placeholderText\"]],\"hello\"]]],false],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/textareas.hbs"
    }
  });

  _exports.default = _default;
});