define("organization-settings/helpers/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NOTICE_MESSAGES = void 0;

  /**
   * Default Notice Messages
   */
  var NOTICE_MESSAGES = Object.freeze({
    SAVED_SUCCESS: {
      style: 'success',
      message: 'Your change has been saved successfully!'
    },
    HAS_ERRORS: {
      style: 'error',
      message: 'Your change could not be saved!'
    },
    SUM_NOT_100_PERCENT: {
      style: 'error',
      message: 'The sum of the values must equal 100!'
    }
  });
  _exports.NOTICE_MESSAGES = NOTICE_MESSAGES;
});