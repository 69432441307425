define("plans/components/printable-plan", ["exports", "plans/templates/components/printable-plan"], function (_exports, _printablePlan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _printablePlan.default,
    classNames: ['printable-plan'],

    /**
     * Computed property that acts as a flag to indicate if only
     * approved items should be shown.
     *
     * @return {Boolean}
     */
    showOnlyApproved: Ember.computed('quality', function () {
      return Ember.isNone(this.quality);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.run.later(function () {
          window.print();
        }, 1000);

        window.onafterprint = function () {
          window.history.back();
        };
      });
    }
  });

  _exports.default = _default;
});