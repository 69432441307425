define("wc-calendar/templates/components/cycle-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Kw6t9Zg",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"cycleMonths\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"cycle-calendar__month\"],[12,\"data-test\",[28,[\"cycle-calendar-month-\",[22,1,[\"dataTest\"]]]]],[9],[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "wc-calendar/templates/components/cycle-calendar.hbs"
    }
  });

  _exports.default = _default;
});