define("workcompass-components/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateText = truncateText;
  _exports.default = void 0;

  function truncateText(params, hash) {
    var value = params[0];
    var len = hash.limit;
    var out = '';

    if (value) {
      out = value.substr(0, len);

      if (value.length > len) {
        out += '...';
      }
    } else {
      out = '';
      value = '';
    }

    if (value !== undefined && value.length > len) {
      out = "<span title=\"".concat(value, "\">").concat(out, "</span>");
    }

    return out;
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});