define("workcompass-components/components/user-card", ["exports", "workcompass-components/templates/components/user-card"], function (_exports, _userCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Natives
    classNames: ['ember-wc-core', 'user-card'],
    layout: _userCard.default
  });

  _exports.default = _default;
});