define("workcompass-components/templates/components/button-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NycjK+pc",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[7,\"span\"],[11,\"class\",\"button-dropdown__selected\"],[12,\"data-value\",[23,[\"current\",\"value\"]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"expand\"],null]],[9],[0,\"\\n  \"],[1,[23,[\"current\",\"display\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isExpanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"button-dropdown__list listbox icolist\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"options\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[7,\"ul\"],[11,\"class\",\"action-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"action-list__item\"],[9],[0,\"\\n            \"],[1,[22,1,[\"display\"]],false],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"select\",[22,1,[\"value\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"nav\"],[11,\"class\",\"action-list action-extras\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"ifNotEmpty\",[[23,[\"addText\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"action-list__add ico ico-add\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"add\"],null]],[9],[0,\"\\n          \"],[1,[21,\"addText\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[14,2],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/button-dropdown.hbs"
    }
  });

  _exports.default = _default;
});