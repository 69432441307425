define("workcompass-components/components/form-controls/checkboxes", ["exports", "workcompass-components/components/form-controls/selection", "workcompass-components/templates/components/form-controls/checkboxes"], function (_exports, _selection, _checkboxes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selection.default.extend({
    layout: _checkboxes.default,
    tagName: 'fieldset',
    classNames: ['form-group-controls'],
    checkboxes: Ember.computed('sortedOptions', 'display', 'value', 'selected', function () {
      var self = this;
      return (this.sortedOptions || []).map(function (opt) {
        return {
          id: "".concat(self.elementId, "_").concat(Ember.String.camelize(opt[self.value])),
          display: opt[self.display],
          value: opt[self.value],
          selected: opt[self.selected]
        };
      });
    })
  });

  _exports.default = _default;
});