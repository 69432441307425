define("plans/components/comment-type", ["exports", "plans/templates/components/comment-type"], function (_exports, _commentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _commentType.default,
    commentTypeClass: function commentTypeClass(commentType) {
      switch (commentType) {
        case 'Coaching feedback':
          return 'comment-list__comment-type-icon--coaching-feedback';

        case 'Comment':
          return 'comment-list__comment-type-icon--comment';

        case 'Rating':
          return 'comment-list__comment-type-icon--rating-event';

        case 'Progress update':
          return 'comment-list__comment-type-icon--progress-update';

        default:
          return 'comment-list__comment-type-icon--change';
      }
    }
  });

  _exports.default = _default;
});