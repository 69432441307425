define("chat/components/chat-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    showTypingIndicator: true,
    planLink: Ember.computed('model.id', function () {
      var planId = this.get('planId');
      return "https://".concat(window.location.hostname, "/app/plans/").concat(planId);
    }),
    first: false,
    ownInput: null,
    endingChat: Ember.computed('model.id', function () {
      var _this = this;

      var possibleEndingPoints = ['reviewer-end', 'staff-member-end'];
      return possibleEndingPoints.any(function (possibleEndingPoint) {
        return possibleEndingPoint == _this.get('model.id');
      });
    }),
    userInputs: [],
    userInputKey: Ember.computed.alias('model.variableName'),
    needsUserInput: Ember.computed.notEmpty('model.variableName'),
    userInput: Ember.computed('userInputs.@each.{key,value}', 'model.variableName', function () {
      var _this2 = this;

      if (this.get('needsUserInput')) {
        if (this.get('ownInput')) {
          return this.get('ownInput');
        } else {
          var match = this.get('userInputs').filter(function (input) {
            return input.key == _this2.get('userInputKey');
          })[0];

          if (match) {
            this.set('ownInput', match.value);
            return match.value;
          }
        }
      }
    }),
    receivedUserInput: Ember.computed.notEmpty('userInput'),
    isCopyable: Ember.computed('waitingForInput', 'model.canBeCopied', function () {
      return this.get('waitingForInput') && this.get('model.canBeCopied');
    }),
    memory: Ember.computed('userInputs.@each.{key,value}', function () {
      var m = {};
      this.get('userInputs').forEach(function (input) {
        m[input.key] = input.value;
      });
      return m;
    }),
    nextResponsePart: null,
    showNextResponse: Ember.computed('needsUserInput', 'receivedUserInput', 'nextResponsePart', function () {
      return this.get('nextResponsePart') && (!this.get('needsUserInput') || this.get('needsUserInput') && this.get('receivedUserInput'));
    }),
    selectedOption: null,
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var waitTime = 0;

      if (this.get('model.id').includes('start')) {
        this.set('showTypingIndicator', false);
      } else {
        waitTime = Math.floor(Math.random() * 200) + this.get('model.content.length') * 20 + this.get('model.options.length') * 40;

        if (waitTime > 3000) {
          waitTime = 3000;
        }
      }

      setTimeout(function () {
        _this3.set('showTypingIndicator', false);

        if (_this3.get('needsUserInput')) {
          _this3.get('showInput')(_this3.get('model.variableName'));
        }
      }, waitTime);

      if (this.get('model.nextResponsePartId')) {
        this.get('store').findRecord('response-part', this.get('model.nextResponsePartId')).then(function (nextResponsePart) {
          _this3.set('nextResponsePart', nextResponsePart);
        });
      }
    },
    inputCleaner: function inputCleaner(text) {
      return text.split('\n').filter(function (line) {
        return line.length > 0;
      }).map(function (line) {
        return line.replace(/\s{2,}/g, ' ').trim();
      }).join('\n');
    },
    actions: {
      optionSelected: function optionSelected(option) {
        if (!this.get('optionSelected')) {
          this.set('optionSelected', true);
          this.set('selectedOption', option);
          this.get('onOptionSelected')(option);
        }
      },
      endingButtonClicked: function endingButtonClicked(shouldSendEmail) {
        var text = Ember.$('.chat-box__chat-bubble--chat-bot').last().text().trim();
        text = this.inputCleaner(text);
        this.get('saveCoachingFeedback')(text, shouldSendEmail);
      },
      editButtonClicked: function editButtonClicked() {
        var text = Ember.$('.chat-box__chat-bubble--chat-bot').last().text().trim();
        text = this.inputCleaner(text);
        this.get('editCoachingFeedback')(text);
      },
      copyContent: function copyContent() {
        var content = this.get('model.content').trim();
        var inputText = this.get('chatInputValue');
        this.set('chatInputValue', inputText + ' ' + content);
        Ember.$('.chat-box__input-textarea').focus();
      }
    }
  });

  _exports.default = _default;
});