define("chat/helpers/enquiry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enquiry = enquiry;
  _exports.default = void 0;

  function enquiry(params) {
    var dataHash = params[0];
    var index = params[1];
    return dataHash[index];
  }

  var _default = Ember.Helper.helper(enquiry);

  _exports.default = _default;
});