define("workcompass-components/services/event-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Handle Event Handler Collection
   */
  var _default = Ember.Service.extend({
    // Properties
    map: {},

    /**
     * Add event listener to the element provided
     * @param {HTMLElement} context   Context HTML element
     * @param {string}      event     Event with namespace (event.namespace)
     * @param {function}    callback  Function to call back when event called
     */
    addListener: function addListener(context, event, callback) {
      if (context) {
        this.map[event] = callback;
        context.addEventListener(event.split('.')[0], this.map[event]);
      }
    },

    /**
     * Remove event listener to the element provided
     * @param {HTMLElement} context   Context HTML element
     * @param {string}      event     Event with namespace (event.namespace)
     */
    removeListener: function removeListener(context, event) {
      if (context) {
        context.removeEventListener(event.split('.')[0], this.map[event]);
        delete this.map[event];
      }
    }
  });

  _exports.default = _default;
});