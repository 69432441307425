define("workcompass-components/components/wc-modal", ["exports", "workcompass-components/templates/components/wc-modal"], function (_exports, _wcModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcModal.default,
    display: false,
    okLabel: 'Ok',
    okDisabled: false,
    cancellable: true,
    cancelLabel: 'Cancel',
    cancelDisabled: false,
    title: '',
    actions: {
      /**
       * Handles the click event for the OK button.
       * 
       * If an `onOk` action is defined, it calls that action.
       * Otherwise, it hides the modal by setting `display` to false.
       *
       * @param {Event} evt - The click event object.
       */
      okClicked: function okClicked(evt) {
        if (this.get('onOk')) {
          this.get('onOk')(evt);
        } else {
          this.set('display', false);
        }
      },
      cancelClicked: function cancelClicked() {
        if (this.get('onCancel')) {
          this.get('onCancel')();
        } else {
          this.set('display', false);
        }
      }
    }
  });

  _exports.default = _default;
});