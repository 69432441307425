define("workcompass-components/components/wc-timeline", ["exports", "workcompass-components/templates/components/wc-timeline"], function (_exports, _wcTimeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcTimeline.default,
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthsToDisplay: Ember.computed('cycle.allPhases', function () {
      var offset = this.get('cycle.allPhases')[0].get('startMonth');
      return this.get('months').slice(offset).concat(this.get('months').slice(0, offset + 1));
    }),
    setCalendarDays: Ember.computed('cycle.allPhases', function () {
      var days = new Array(365 + 30);
      var oneDay = 1000 * 60 * 60 * 24;
      var offsetDay = this.get('cycle.allPhases')[0].get('startOn').getDate() - 1;
      var offset = parseInt(this.get('cycle.allPhases')[0].get('startOn') / oneDay) - offsetDay;
      var startPlan = parseInt(this.get('cycle.allPhases')[0].get('startOn') / oneDay) - offset;
      var endPlan = parseInt(this.get('cycle.allPhases').slice(-1).pop().get('endOn') / oneDay) - offset;
      var today = parseInt(new Date() / oneDay) - offset;
      var i;

      for (i = startPlan; i <= endPlan; i++) {
        if (i < today) {
          days[i] = {
            active: true,
            past: true
          };
        } else if (i == today) {
          days[i] = {
            active: true,
            today: true,
            date: new Date((startPlan + offset - 1) * oneDay)
          };
        } else if (i > today) {
          days[i] = {
            active: true,
            current: true
          };
        }
      }

      return days;
    }),
    calendarDays: Ember.computed('cycle.allPhases', function () {
      var days = this.get('setCalendarDays');
      var oneDay = 1000 * 60 * 60 * 24;
      var offsetDay = this.get('cycle.allPhases')[0].get('startOn').getDate() - 1;
      var offset = parseInt(this.get('cycle.allPhases')[0].get('startOn') / oneDay) - offsetDay;
      var today = parseInt(new Date() / oneDay) - offset;
      this.get('cycle.allPhases').forEach(function (phase, index) {
        var start = parseInt(phase.get('startOn') / oneDay) - offset;
        days[start] = {
          active: true,
          past: start < today,
          today: start == today,
          current: start > today,
          label: phase.get('label') || "Check-in ".concat(index)
        };
      });
      return days;
    })
  });

  _exports.default = _default;
});