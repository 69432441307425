define("plans/components/printable-item", ["exports", "plans/components/item-card"], function (_exports, _itemCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemCard.default.extend({});

  _exports.default = _default;
});