define("workcompass-components/templates/components/wc-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZtiY1ZlV",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select\",null,[[\"selected\",\"options\",\"onchange\",\"searchEnabled\",\"searchField\"],[[23,[\"choice\"]],[23,[\"options\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"choice\"]]],null]],null],[23,[\"searchEnabled\"]],\"label\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"wc-dropdown-option\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,1,[\"label\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,1,[]],true],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-dropdown.hbs"
    }
  });

  _exports.default = _default;
});