define("plans/templates/components/printable-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p7mn9LNT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"printable-plan-item printable-career-discussion\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card__header\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row card__title\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-1 card__first-col\"],[9],[0,\"\\n          \"],[7,\"h1\"],[11,\"class\",\"card__number\"],[9],[0,\" \"],[1,[21,\"number\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col pl-0\"],[9],[0,\"\\n          \"],[7,\"h1\"],[11,\"class\",\"wc-card__description\"],[9],[0,\" \"],[1,[23,[\"question\",\"text\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card__body\"],[9],[0,\"\\n      \"],[1,[27,\"printable-comments-and-changes\",null,[[\"comments\",\"emptyText\"],[[23,[\"question\",\"orderedComments\"]],\"No comments\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/components/printable-question.hbs"
    }
  });

  _exports.default = _default;
});