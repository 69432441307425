define("organization-settings/templates/password-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b3GQv9rC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"wc-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"input\"],[11,\"id\",\"password_auth\"],[11,\"name\",\"password_auth\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"changeState\"],null]],[12,\"checked\",[21,\"enabled\"]],[12,\"disabled\",[21,\"mandatory\"]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"password_auth\"],[12,\"class\",[27,\"if\",[[23,[\"mandatory\"]],\"disabled\",\"\"],null]],[9],[0,\"Enable\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"ligther-text\"],[9],[0,\"\\n    \"],[7,\"i\"],[9],[0,\"Enable or disable password-based authentication. Disabling the password-based authentication will not present user with WorkCompass login form. This option can only be disabled if another authentication mode has been setup.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"mandatory\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"warning\"],[9],[0,\"Password-based authentication cannot be disabled as no others login system has been enabled.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/password-auth.hbs"
    }
  });

  _exports.default = _default;
});