define("workcompass-components/templates/components/form-controls/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AMoZCR/g",
    "block": "{\"symbols\":[\"opt\",\"@placeholder\",\"@label\",\"@name\",\"@disabled\",\"@required\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[22,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"control-label\"],[12,\"for\",[28,[[22,0,[\"elementId\"]],\"-select\"]]],[9],[1,[22,3,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[8,\"select\"],[12,\"id\",[28,[[22,0,[\"elementId\"]],\"-select\"]]],[12,\"name\",[22,4,[]]],[12,\"disabled\",[22,5,[]]],[12,\"required\",[22,6,[]]],[13,7],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"needPlaceHolder\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[11,\"value\",\"\"],[11,\"disabled\",\"\"],[11,\"selected\",\"\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[\"value\"]]],[12,\"selected\",[22,1,[\"selected\"]]],[9],[1,[22,1,[\"display\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[14,8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/form-controls/select.hbs"
    }
  });

  _exports.default = _default;
});