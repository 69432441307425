define("organization-settings/adapters/templates-surveys-type-abstract", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    namespace: 'api/templates/surveys',
    // Protected
    // Extract the appropriate survey type
    _getSurveyType: function _getSurveyType(type) {
      // Remove suffix survey
      var surveyType = type.replace('Survey', ''); // Plurialize the type

      return (0, _emberInflector.pluralize)(surveyType).toLowerCase();
    }
  });

  _exports.default = _default;
});