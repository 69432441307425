define("plans/models/rating", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    rater: _emberData.default.belongsTo('staff-member'),
    ratedAt: _emberData.default.attr('date'),
    commentType: 'Rating',
    label: _emberData.default.attr('string'),
    createdAt: Ember.computed.alias('ratedAt'),
    score: _emberData.default.attr('number'),
    textToDisplay: Ember.computed('label', function () {
      var label = this.get('label');
      return "Rating changed to \"".concat(label, "\"");
    }),
    user: Ember.computed.alias('rater')
  });

  _exports.default = _default;
});