define("plans/templates/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sUwAFd1Y",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"printable-plan\",null,[[\"plan\",\"quality\",\"usesPercentageGoalRatings\"],[[23,[\"plan\"]],[23,[\"quality\"]],[23,[\"session\",\"currentUser\",\"usesPercentageGoalRatings\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/print.hbs"
    }
  });

  _exports.default = _default;
});