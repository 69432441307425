define("plans/controllers/team-bar/staff-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    window: Ember.inject.service(),
    invited: false,
    needsInvitation: Ember.computed('staffMember.needsInvitation', 'invited', function () {
      return !this.invited && this.get('staffMember.needsInvitation');
    }),
    actions: {
      inviteUser: function inviteUser() {
        var _this = this;

        var staffMember = this.get('staffMember');
        staffMember.invite().then(function () {
          _this.set('invited', true);
        }).catch(function () {// TODO: Handle error here
        });
      },
      redirectToNewPlan: function redirectToNewPlan() {
        var staffMember = this.get('staffMember');
        this.window.redirectTo("/users/".concat(staffMember.id, "/plans/new"));
      }
    }
  });

  _exports.default = _default;
});