define("design-system/controllers/components/icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    icons: ['add-plus-icon.png', 'delete-icon.png', 'private-comment-icon-enabled.png', 'alert-icon.png', 'edit-icon-orange.png', 'private-comment-icon-enabled-small.png', 'arrow.svg', 'edit-icon.png', 'rating-icon-grayed-out.png', 'attachment-icon.png', 'edit.svg', 'rating-icon.png', 'avatar.png', 'error-icon.png', 'remove-minus-icon.png', 'file-icon.png', 'send-btn-orange.png', 'bot-avatar-outline.png', 'goal-weight-icon.png', 'send-icon.png', 'bot-avatar-small.png', 'hoverbox-line.png', 'send-message-icon.png', 'kebab-menu-icon.png', 'side-navbar-icon.svg', 'milestone-calendar-icon-grayed-out.png', 'side-navbar-submenu-line.png', 'chat-box__header-indicator-online.png', 'milestone-calendar-icon.png', 'small-star.png', 'close-button-lighter.png', 'milestone-due-icon-grayed-out.png', 'success-icon.png', 'close-button.png', 'milestone-due-icon.png', 'supported-goal-icon.png', 'comment-connector.png', 'note-icon.png', 'supported_icon_grey.png', 'comment-horizontal-connector.png', 'orange-dropdown-arrow.png', 'supported_icon.png', 'comment-icon.png', 'plus-icon.svg', 'thumb-esther.jpg', 'copy-paste15px.png', 'print-icon.png', 'warning-icon.png', 'copy-paste1.png', 'private-comment-disabled.png', 'copypaste-small.png', 'private-comment-icon-disabled-small.png']
  });

  _exports.default = _default;
});