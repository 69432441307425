define("plans/components/team-bar", ["exports", "plans/templates/components/team-bar"], function (_exports, _teamBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * Get the item to display in the item actions
   * for provided member
   * @param {StaffMember}   member  Staff member object
   * @returns {Array} Array of item to display
   */
  function mapItems(member) {
    return (member.get('plans') || []).filter(function (plan) {
      return plan.get('tasks.length') > 0;
    }).map(function (plan) {
      return {
        display: "".concat(plan.get('dueYear'), " Plan"),
        subdisplay: plan.get('tasks').length ? "".concat(plan.get('tasks').length, " actions") : '',
        value: {
          route: 'team-bar.plan',
          model: plan.get('id')
        }
      };
    });
  }
  /**
   * Generate item compatible with
   * badge-menu format.
   * @param {Task}  survey    Survey object
   * @returns {object} Item object with url
   */


  function buildSurveyItem(survey) {
    var mainRoute = survey.query === 'new_engagement_survey' ? 'engagement_surveys' : 'leadership_surveys';
    var finalPath = survey.query === 'leadership_survey_complete' ? 'results' : 'responses/new';
    var display = survey.query === 'new_engagement_survey' ? 'Engagement Survey' : 'Leadership Survey';
    var subdisplay = survey.query === 'leadership_survey_complete' ? 'Follow up' : 'To Complete';
    return {
      display: display,
      subdisplay: subdisplay,
      value: {
        url: "/".concat(mainRoute, "/").concat(survey.subjectId, "/").concat(finalPath)
      }
    };
  }
  /**
   * Generate list of items for badge menu
   * for all type of survey
   * @param {StaffMember}   member  Member with survey
   * @returns {Array} Array of item
   */


  function mapSurveys(member) {
    return [].concat(_toConsumableArray((member.get('engagementSurvey') || []).map(buildSurveyItem)), _toConsumableArray((member.get('leadershipSurvey') || []).map(buildSurveyItem)), _toConsumableArray((member.get('newLeadershipSurvey') || []).map(buildSurveyItem)));
  }
  /**
   * Rebuild data to fit data listing
   * @param {StaffMember}   member      Staff member object
   * @param {StaffMember}   owner       Current user viewing & owning task
   * @param {object}        permissions Permission list
   * @returns {Array} List of member alternated to fill list
   */


  function build(members, owner, permissions) {
    var groupPermission = Object.assign({
      canAdd: false
    }, permissions);

    var _iterator = _createForOfIteratorHelper(members),
        _step;

    try {
      var _loop = function _loop() {
        var member = _step.value;
        var surveys = mapSurveys(member);
        member.items = [].concat(_toConsumableArray(surveys), _toConsumableArray(mapItems(member)));
        member.needsPlanForCycle = (owner.get('createPlanTasks') || []).filter(function (task) {
          return task.onBehalfOfId == member.get('id');
        }).length > 0;
        member.canAdd = groupPermission.canAdd;
        member.hasTasks = member.items.length > 0 || member.needsPlanForCycle || member.canAdd;
        member.totalTasks = surveys.length + (member.needsPlanForCycle ? 1 : 0) + (member.get('needsInvitation') ? 1 : 0) + (member.get('plans') || []).reduce(function (accumulator, plan) {
          return accumulator + plan.tasks.length;
        }, 0);
      };

      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return members;
  }

  var _default = Ember.Component.extend({
    // Service
    window: Ember.inject.service(),
    // Native
    classNames: ['teambar'],
    layout: _teamBar.default,
    // Computed
    selected: Ember.computed.or('active', 'current'),
    groups: Ember.computed(function () {
      var _this = this;

      var groupNames = Object.keys(this.get('attrs')).filter(function (key) {
        var prefix = 'group-';

        var groupName = _this.get('attrs')[key];

        if (!key.startsWith(prefix)) {
          return false;
        }

        return !Ember.isEmpty(_this[Ember.String.camelize(groupName)]);
      }).map(function (key) {
        return _this.get('attrs')[key];
      });
      return groupNames;
    }),
    members: Ember.computed('current', function () {
      var ret = {};

      var _iterator2 = _createForOfIteratorHelper(this.groups),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var group = _step2.value;
          var permissions = {
            canAdd: false
          };

          if (this.hasOwnProperty('canAdd')) {
            permissions.canAdd = this.canAdd.includes(Ember.String.camelize(group));
          }

          if (!Ember.isEmpty(this[Ember.String.camelize(group)])) {
            ret[Ember.String.camelize(group)] = build(this[Ember.String.camelize(group)].slice(), this.current, permissions);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return ret;
    }),
    actions: {
      createPlan: function createPlan(memberId) {
        // TODO: Use window redirect due to legacy
        // Replace with transtion once smart-plan engine
        // done.
        this.window.redirectTo("/users/".concat(memberId, "/plans/new"));
      },
      inviteUser: function inviteUser(member) {
        member.invite().then(function () {
          member.set('needsInvitation', false);
        }).catch(function () {//TODO: Handle error if invite fail.
        });
      },
      onItemSelected: function onItemSelected(routing) {
        if (routing.hasOwnProperty('url')) {
          this.window.redirectTo(routing.url);
          return;
        }

        this.onRedirect(routing);
      },
      onSwitchUser: function onSwitchUser(member, evt) {
        // If child prevented the default
        // Do not switch.
        // eg click on badge number does not
        // require switch
        if (evt.defaultPrevented) return;
        this.onRedirect({
          route: member.get('needsInvitationOrHasNoPlan') ? 'team-bar.staff-member' : 'team-bar.plan',
          model: member.get('needsInvitationOrHasNoPlan') ? member.get('id') : member.get('mostRecentPlan.id')
        });
      }
    }
  });

  _exports.default = _default;
});