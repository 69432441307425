define("plans/templates/team-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V585s347",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"session\",\"currentUser\",\"showTeambar\"]]],null,{\"statements\":[[0,\"  \"],[5,\"team-bar\",[],[[\"@current\",\"@active\",\"@group-1\",\"@myTeam\",\"@group-2\",\"@canAdd\",\"@myReviewees\",\"@onRedirect\"],[[23,[\"teamBar\",\"staffMember\"]],[21,\"active\"],\"My Team\",[21,\"team\"],\"My Reviewees\",\"myTeam\",[23,[\"teamBar\",\"additionalReviewees\"]],[27,\"action\",[[22,0,[]],\"redirectTo\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"content-center\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/team-bar.hbs"
    }
  });

  _exports.default = _default;
});