define("plans/models/comment", ["exports", "ember-data", "plans/models/progress-update-text"], function (_exports, _emberData, _progressUpdateText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _progressUpdateText.default.extend({
    commenter: _emberData.default.belongsTo('staff-member'),
    milestone: _emberData.default.belongsTo('milestone'),
    text: _emberData.default.attr('string'),
    private: _emberData.default.attr('boolean'),
    commentableType: _emberData.default.attr('string'),
    commentableId: _emberData.default.attr('number'),
    commentType: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    textToDisplay: Ember.computed('commentType', 'text', 'commentableId', 'milestone', function () {
      if (this.get('commentType') === 'Progress update') {
        var text = this.get('text');
        return this.progressUpdateText(text, false);
      } else {
        return this.get('text');
      }
    }),
    isCoachingFeedback: Ember.computed.equal('commentType', 'Coaching feedback'),
    isNotCoachingFeedback: Ember.computed.not('isCoachingFeedback'),
    user: Ember.computed.alias('commenter')
  });

  _exports.default = _default;
});