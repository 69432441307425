define("design-system/templates/components/icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6TqUh/T6",
    "block": "{\"symbols\":[\"icon\",\"index\"],\"statements\":[[7,\"h1\"],[9],[0,\" Icons \"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"icons\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"wc-color-palette-card\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"wc-color-palette-card-container\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[28,[\"/assets/images/\",[22,1,[]]]]],[9],[10],[0,\"\\n          \"],[1,[27,\"wc-copy-icon\",null,[[\"text\",\"index\"],[[22,1,[]],[22,2,[]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/icons.hbs"
    }
  });

  _exports.default = _default;
});