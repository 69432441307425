define("organization-settings/helpers/arr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arr = arr;
  _exports.default = void 0;

  function arr(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(arr);

  _exports.default = _default;
});