define("organization-settings/routes/model-router", ["exports", "organization-settings/routes/abstract-router", "ember-inflector"], function (_exports, _abstractRouter, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This router is for organization setting routes that
   * are based of a model
   */
  var _default = _abstractRouter.default.extend({
    /**
     * Retrieve model(s) based on route
     *
     * Fetches either a specific model if an ID is provided as a parameter,
     * or retrieves a collection of models based on the route name.
     *
     * @param   {*} params - Parameters for fetching the model(s)
     * @returns {*} - The requested model or collection of models based on the route name.
     */
    model: function model(params) {
      var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      var modelName = inflector.singularize(this.routeName); // Look for specific record if an id is specified

      if ('id' in params) {
        return this.store.findRecord(modelName, params.id);
      }

      return this.store.findAll(modelName);
    }
  });

  _exports.default = _default;
});