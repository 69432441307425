define("workcompass-components/components/charts/bar", ["exports", "workcompass-components/components/charts/base", "workcompass-components/templates/components/charts/bar"], function (_exports, _base, _bar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    layout: _bar.default,
    tagName: 'dl',

    /**
     * Calculate position of the vertical line
     * for each interval.
     * The percentage position is determine via
     * the interval being converted into percentage
     * whereby the denominator = 100%
     */
    verticalLines: Ember.computed('interval', 'denominator', function () {
      var denominator = parseInt(this.denominator) || 100;
      var step = parseInt(this.interval) || 1;
      var lines = [];

      for (var i = step; i <= denominator; i += step) {
        // Convert a step in percents
        var left = "".concat(i * 100 / denominator, "%");
        lines.push({
          left: left,
          label: i
        });
      }

      return lines;
    }),

    /**
     * Convert each data value provided into percentage
     * to draw the width of each bar.
     * Percentage calculated based on the denominator provided
     */
    datalist: Ember.computed('dataset', 'display', 'value', 'denominator', 'classes', function () {
      var _this = this;

      return (this.dataset || []).map(function (data) {
        var denominator = parseInt(_this.denominator) || 100;
        var percent = Math.round(parseFloat(data[_this.value]) * 100 / denominator);
        return {
          display: data[_this.display],
          percent: percent,
          value: data[_this.value],
          classes: (data[_this.classes] || []).join(' ')
        };
      });
    })
  });

  _exports.default = _default;
});