define("workcompass-components/components/milestone-slider", ["exports", "workcompass-components/templates/components/milestone-slider"], function (_exports, _milestoneSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _milestoneSlider.default,
    change: function change(event) {
      event.stopPropagation();
      this.get('onChange')(event.target.value);
    }
  });

  _exports.default = _default;
});