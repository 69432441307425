define("design-system/controllers/components/kebab-menus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    options: [],
    actions: {
      someAction: function someAction(dropdown) {
        dropdown.actions.close();
      },
      anotherAction: function anotherAction() {}
    }
  });

  _exports.default = _default;
});