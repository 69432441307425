enifed("ember-template-compiler/lib/plugins/assert-reserved-named-arguments", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertReservedNamedArguments;
  var RESERVED = ['@arguments', '@args', '@block', '@else'];
  var isReserved, assertMessage;

  function assertReservedNamedArguments(env) {
    var moduleName = env.meta.moduleName;
    return {
      name: 'assert-reserved-named-arguments',
      visitor: {
        PathExpression: function (_ref) {
          var original = _ref.original,
              loc = _ref.loc;

          if (isReserved(original)) {
            true && !false && (0, _debug.assert)(assertMessage(original) + " " + (0, _calculateLocationDisplay.default)(moduleName, loc));
          }
        }
      }
    };
  }

  if (true
  /* EMBER_GLIMMER_NAMED_ARGUMENTS */
  ) {
      isReserved = function (name) {
        return RESERVED.indexOf(name) !== -1 || Boolean(name.match(/^@[^a-z]/));
      };

      assertMessage = function (name) {
        return "'" + name + "' is reserved.";
      };
    } else {
    isReserved = function (name) {
      return name[0] === '@';
    };

    assertMessage = function (name) {
      return "'" + name + "' is not a valid path.";
    };
  }
});