define("design-system/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('components/buttons');
    this.route('components/modals');
    this.route('components/notices');
    this.route('components/select');
    this.route('components/kebab-menus');
    this.route('components/cards');
    this.route('components/dropdown');
    this.route('components/textareas');
    this.route('components/inputs');
    this.route('components/avatars');
    this.route('components/sliders');
    this.route('components/labels');
    this.route('components/links');
    this.route('components/button-groups');
    this.route('components/typography');
    this.route('components/color-palette');
    this.route('components/icons');
    this.route('components/copy-icon');
    this.route('components/grid-list');
    this.route('components/expandable');
    this.route('components/icon-lines');
    this.route('components/sidebar-widgets');
    this.route('components/pagination');
    this.route('components/spinner');
    this.route('components/popovers');
  });

  _exports.default = _default;
});