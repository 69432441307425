define("plans/models/coaching-feedback", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    text: _emberData.default.attr('string'),
    recipientId: _emberData.default.attr('number'),
    milestoneId: _emberData.default.attr('number')
  });

  _exports.default = _default;
});