define("plans/components/item-card", ["exports", "plans/templates/components/item-card"], function (_exports, _itemCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _itemCard.default,
    itemType: Ember.computed.alias('item.constructor.modelName'),
    isGoalItem: Ember.computed.equal('itemType', 'goal'),
    isMultipleChoiceItem: Ember.computed.equal('itemType', 'competency'),
    isFreeTextItem: Ember.computed.equal('itemType', 'development-need'),
    isQuestionItem: Ember.computed.equal('itemType', 'question'),
    itemNumber: Ember.computed('index', function () {
      return this.get('index') + 1;
    })
  });

  _exports.default = _default;
});