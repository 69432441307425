define("design-system/controllers/components/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    options: [{
      label: '<span class="comment-list__comment-type">All</span>',
      value: 0
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--comment\"></span>\n      <span class=\"comment-list__comment-type\">Comment</span>\n      ",
      value: 1
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--rating-event\"></span>\n      <span class=\"comment-list__comment-type\">Rating</span>\n      ",
      value: 2
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--progress-update\"></span>\n      <span class=\"comment-list__comment-type\">Progress update</span>\n      ",
      value: 3
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--coaching-feedback\"></span>\n      <span class=\"comment-list__comment-type\">Coaching feedback</span>\n      ",
      value: '4'
    }],
    choice: {
      label: '<span class="comment-list__comment-type">All</span>',
      value: 0
    }
  });

  _exports.default = _default;
});