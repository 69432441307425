define("workcompass-components/components/form-controls/input", ["exports", "workcompass-components/components/form-controls/control", "workcompass-components/templates/components/form-controls/input"], function (_exports, _control, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _control.default.extend({
    layout: _input.default
  });

  _exports.default = _default;
});