define("ember-wc-360/controllers/programs", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // Common denomitor for rating
  var DENOMINATOR = 5;
  var LABEL_EMPLOYEE_TYPES = {
    MANAGERS: 'Managers',
    PEERS: 'Peers',
    DIRECT_REPORTS: 'Direct Reports'
  };
  /**
   * Convert value to common
   * denominator
   * @param {string} value
   * @returns {float}
   */

  function convertToCommonDenominator(value) {
    var resultParts = value.split('/');
    return parseFloat(resultParts[0]) * DENOMINATOR / parseFloat(resultParts[1]);
  }
  /**
   * Array callback to aggregate result
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
   * @param {number} accumulator
   * @param {Result} result
   * @returns {number} Accumulator
   */


  function cumulateResult(accumulator, result) {
    var x = convertToCommonDenominator(result.value);
    return accumulator + x;
  }
  /**
   * Get the overall cumulated rating
   * @param {array} datapoints
   * @param {number} start
   * @returns {number} The cumulation of rating
   */


  function getOverallRating(datapoints, start) {
    var total = datapoints.toArray().reduce(cumulateResult, start || 0);
    return (total / datapoints.length).toFixed(1);
  }
  /**
   * Cumulate rating result per target type
   * @param {Map} mapped
   * @param {object} datapoint
   * @returns {Map}
   */


  function groupTargetDatapoint(mapped, datapoint) {
    if (!mapped.has(datapoint.targetType)) {
      mapped.set(datapoint.targetType, {
        label: LABEL_EMPLOYEE_TYPES[(0, _emberInflector.pluralize)(datapoint.targetType || '').toUpperCase()],
        // Camelize to prevent scss-linter
        // to flag BEM class name
        classNames: [Ember.String.camelize((0, _emberInflector.pluralize)(datapoint.targetType || '').toLowerCase())],
        ratings: []
      });
    }

    var value = convertToCommonDenominator(datapoint.value);
    mapped.get(datapoint.targetType).ratings.push(value);
    return mapped;
  }
  /**
   * Retrieve target datapoint entries
   * @param {array} datapoints
   * @returns {array} Array of array of key/value
   */


  function retrieveTargetDatapoint(datapoints) {
    return Array.from(datapoints.reduce(groupTargetDatapoint, new Map()).entries());
  }
  /**
   * Combine the result of each target
   * datapoint
   * @param {array} param0
   * @returns {array}
   */


  function cumulateTargetResult(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        datapoint = _ref2[1];

    var rating = datapoint.ratings.reduce(function (cumulate, rating) {
      return cumulate + parseFloat(rating);
    }, 0);
    rating = (rating / datapoint.ratings.length).toFixed(1);
    return {
      label: datapoint.label,
      classNames: datapoint.classNames,
      rating: rating
    };
  }
  /**
   * Get the overall rating per employee type
   * for the graph
   * @param {array} datapoints
   * @returns {array} Array of object with label & rating
   */


  function getByTargetTypes(datapoints) {
    return retrieveTargetDatapoint(datapoints.toArray()).map(cumulateTargetResult);
  }
  /**
   * Format & reduce datapoints collection
   * @param {Map} cumulator
   * @param {Datapoint} datapoint
   * @returns {Map}
   */


  function reduceDatapoints(map, datapoint) {
    var grouper = datapoint.section || datapoint.category;

    if (!map.has(grouper)) {
      map.set(grouper, {
        label: datapoint.section || datapoint.label,
        description: datapoint.sectionDescription || datapoint.description,
        category: datapoint.category,
        kind: datapoint.kind,
        overall: 0,
        datapoints: []
      });
    } // Exclude datapoint with null value


    if (!Ember.isBlank(datapoint.value)) {
      map.get(grouper).datapoints.push(datapoint);
    }

    return map;
  }
  /**
   * Sort datapoint by kind
   * @param {Datapoint} a
   * @param {Datapoint} b
   * @returns {numeric}
   */


  function sortDatapoints(a, b) {
    if (a.kind < b.kind) return -1;
    if (a.kind > b.kind) return 1;
    return 0;
  }

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    window: Ember.inject.service(),
    // Flag if confirmation close
    // dialog is display
    closeDialogOpened: false,

    /**
     * Provide the date when program is closed
     * return null if program is not closed yet
     */
    closedOn: Ember.computed('model.surveyProgram.closedOn', function () {
      if (Ember.isBlank(this.model.surveyProgram.closedOn)) {
        return null;
      }

      var options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      return new Intl.DateTimeFormat('default', options).format(new Date(this.model.surveyProgram.closedOn));
    }),

    /**
     * Flag indicating if there is feedback
     * data to display
     */
    hasFeedbacks: Ember.computed('feedbacks', function () {
      return this.feedbacks.length > 0;
    }),

    /**
     * Computed property collecting aggregate result
     * return array of feedback object with overall scrore
     * e.g.:
     * [{
     *  overall: 3.5,
     *  grouped: {
     *    targetType
     *  }
     * }]
     */
    feedbacks: Ember.computed('model.program.datapoints.@each', function () {
      // Group by category
      var categories = (this.model.program.datapoints.toArray() || []).reduce(reduceDatapoints, new Map());
      categories.forEach(function (collection, key) {
        if (collection.kind === 'rating') {
          categories.get(key).overall = getOverallRating(collection.datapoints, categories.get(key).overall);
          categories.get(key).grouped = {};
          categories.get(key).grouped.targetTypes = getByTargetTypes(collection.datapoints);
        }
      }); // Only return those that has datapoints
      // TODO: Currently sorting based kind (rating then textarea)

      return Array.from(categories, function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            value = _ref4[1];

        return value;
      }).sort(sortDatapoints).filter(function (value) {
        return value.datapoints.length > 0;
      });
    }),
    actions: {
      /**
       * Update state of the dialog to open
       */
      openConfirmation: function openConfirmation() {
        this.set('closeDialogOpened', true);
      },

      /**
       * Update state of dialog to close
       */
      onDialogClose: function onDialogClose() {
        this.set('closeDialogOpened', false);
      },

      /**
       * Call to update the program
       * close date
       */
      closeProgram: function closeProgram() {
        var _this = this;

        this.store.findRecord('survey-program', this.model.program.id).then(function (surveyProgram) {
          surveyProgram.set('closedOn', new Date());
          surveyProgram.save();

          _this.set('closeDialogOpened', false);

          _this.model.program.set('closedOn', surveyProgram.closedOn);
        });
      }
    }
  });

  _exports.default = _default;
});