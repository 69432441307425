define("design-system/controllers/components/expandable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isExpanded: false,
    actions: {
      toggleDetails: function toggleDetails() {
        this.set('isExpanded', !this.get('isExpanded'));
      }
    }
  });

  _exports.default = _default;
});