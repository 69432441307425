define("organization-settings/templates/components/editable-rating-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6+l2ShGp",
    "block": "{\"symbols\":[\"ratingOptionTemplate\",\"index\"],\"statements\":[[4,\"wc-modal\",null,[[\"title\",\"display\",\"cancellable\",\"onOk\"],[\"Add rating\",[23,[\"showModal\"]],false,[27,\"action\",[[22,0,[]],\"submitIfValid\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-7\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"template\",\"displayableRatingOptionTemplates\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"editable-rating-option\",null,[[\"ratingOptionTemplate\",\"adjustScores\",\"index\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"adjustScores\"],null],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"editable-rating-options__add-button\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"editable-rating-options__add-button-icon\"],[9],[0,\"+\"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"editable-rating-options__add-button-label\"],[9],[0,\"Add another rating\"],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"addRatingOption\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/components/editable-rating-options.hbs"
    }
  });

  _exports.default = _default;
});