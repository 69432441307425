define("organization-settings/components/plan-template-header", ["exports", "organization-settings/templates/components/plan-template-header"], function (_exports, _planTemplateHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _planTemplateHeader.default,
    planYear: Ember.computed('', function () {
      var year = new Date().getFullYear();
      return "".concat(year, " Plan");
    })
  });

  _exports.default = _default;
});