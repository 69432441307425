define("workcompass-components/components/priority-task", ["exports", "workcompass-components/templates/components/priority-task"], function (_exports, _priorityTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _priorityTask.default,
    store: Ember.inject.service(),
    reviewTask: null,
    messageText: null,
    buttonText: null,
    setProperties: Ember.observer('task.taskType', 'plan.{archivable,cancelableCheckIn}', 'reviewTask', function () {
      if (this.get('task.taskType') === 'review_plan') {
        this.set('reviewTask', 'review_plan');
        this.set('messageText', 'You need to review progress and rate this plan');
        this.set('buttonText', 'Complete final review');
      } else if (this.get('task.taskType') === 'check_in') {
        this.set('reviewTask', 'check_in_plan');
        this.set('messageText', 'You need to check in on the progress of this plan');
        this.set('buttonText', 'Complete check-in');
      } else if (this.get('plan.archivable')) {
        this.set('reviewTask', 'review_plan');
        this.set('messageText', 'Review in progress');
        this.set('buttonText', 'Complete final review');
      } else if (this.get('plan.cancelableCheckIn')) {
        this.set('reviewTask', 'check_in_plan');
        this.set('messageText', 'Check-in phase in progress');
        this.set('buttonText', 'Complete check-in');
      }
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.set('reviewTask', '');
    },
    actions: {
      review: function review() {
        var _this = this;

        var plan = this.get('plan');
        var archivable = this.get('plan.archivable');
        var task = this.get('reviewTask');
        plan.review({
          review: task
        }).then(function () {
          if (task === 'review_plan' && archivable) {
            plan.set('reopenable', _this.get('plan.archivable'));
            plan.set('archivable', false);
            plan.set('updatedAt', new Date());
            plan.set('status', 'archived');
          }

          plan.set('priorityTask', null);
          plan.set('cancelableCheckIn', false);
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});