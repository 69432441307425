define("organization-settings/adapters/templates-surveys-category", ["exports", "organization-settings/adapters/templates-surveys-type-abstract"], function (_exports, _templatesSurveysTypeAbstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _templatesSurveysTypeAbstract.default.extend({
    // Overwrite query to customize URL
    query: function query(store, model, _query) {
      if (Ember.isEmpty(_query)) {
        return Promise.reject();
      }

      if (Ember.isEmpty(_query.filter)) {
        return Promise.reject();
      }

      if (Ember.isEmpty(_query.filter.surveyType)) {
        return Promise.reject();
      }

      var url = this.urlForQuery(_query);
      return this.ajax(url);
    },
    urlForQuery: function urlForQuery(query) {
      var surveyType = this._getSurveyType(query.filter.surveyType);

      return "/".concat(this.namespace, "/").concat(surveyType, "/categories/");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelname, snapshot) {
      var surveyType = this._getSurveyType(snapshot.record.surveyType);

      return "/".concat(this.namespace, "/").concat(surveyType, "/categories/").concat(id);
    }
  });

  _exports.default = _default;
});