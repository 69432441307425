define("organization-settings/components/item-template-card", ["exports", "organization-settings/templates/components/item-template-card", "ember-autosave", "organization-settings/components/save-with-notification"], function (_exports, _itemTemplateCard, _emberAutosave, _saveWithNotification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _saveWithNotification.default.extend({
    layout: _itemTemplateCard.default,
    number: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),
    draftText: Ember.computed('itemTemplate.draftText', 'itemTemplateProxy.draftText', {
      get: function get() {
        return this.get('itemTemplate.draftText');
      },
      set: function set(key, value) {
        this.set('itemTemplateProxy.draftText', value);
        return value;
      }
    }),
    itemTemplateProxy: (0, _emberAutosave.default)('itemTemplate', {
      save: function save(model) {
        this.saveProxy(model);
      }
    }),
    setIsEditing: function setIsEditing() {
      this.set('isEditing', true);
    },
    actions: {
      deleteQuestion: function deleteQuestion() {
        this.set('itemTemplateProxy.markedForArchivalAt', new Date());
      },
      selectText: function selectText() {
        this.setIsEditing();
        this.$('input.wc-card__description--item-template').select();
      },
      focusOut: function focusOut() {
        this.set('isEditing', false);
      }
    }
  });

  _exports.default = _default;
});