define("design-system/templates/components/avatars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vCPxxgGQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Avatars \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Avatars are used to quickly identify users or teams. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass avatars are used on the plan page header, the template page header and when displaying ratings. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of an avatar. It has the class of wc-avatar.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <img class='wc-avatar ' src='/assets/images/avatar.png'>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"img\"],[11,\"class\",\"wc-avatar \"],[11,\"src\",\"/assets/images/avatar.png\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/avatars.hbs"
    }
  });

  _exports.default = _default;
});