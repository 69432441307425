define("workcompass-components/components/comment-list", ["exports", "workcompass-components/templates/components/comment-list"], function (_exports, _commentList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _commentList.default,
    limit: 2,
    showAll: false,
    emailCoachingFeedback: function emailCoachingFeedback() {// noop, override if needed
    },
    allFilterOptions: [{
      label: '<span class="comment-list__comment-type">All</span>',
      value: 'All'
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--comment\"></span>\n      <span class=\"comment-list__comment-type\">Comment</span>\n      ",
      value: 'Comment'
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--rating-event\"></span>\n      <span class=\"comment-list__comment-type\">Rating</span>\n      ",
      value: 'Rating'
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--progress-update\"></span>\n      <span class=\"comment-list__comment-type\">Progress update</span>\n      ",
      value: 'Progress update'
    }, {
      label: "\n      <span class=\"comment-list__comment-type-icon comment-list__comment-type-icon--coaching-feedback\"></span>\n      <span class=\"comment-list__comment-type\">Coaching feedback</span>\n      ",
      value: 'Coaching feedback'
    }],
    filter: Ember.computed('allFilterOptions', function () {
      var options = this.get('allFilterOptions');

      if (!options) {
        return null;
      }

      return options[0];
    }),
    showFilter: Ember.computed('comments', function () {
      return this.get('commentTypes.length') > 1;
    }),
    commentTypes: Ember.computed('comments', function () {
      return this.get('comments').map(function (c) {
        return c.get('commentType');
      }).uniq();
    }),
    commentFilter: Ember.computed('comments', 'commentTypes', 'allFilterOptions', function () {
      var _this = this;

      var totalFilters = [];
      ['All'].concat(this.get('commentTypes')).forEach(function (ct) {
        var option = _this.get('allFilterOptions').find(function (opt) {
          return opt.value === ct;
        });

        totalFilters.push(option);
      });
      return totalFilters;
    }),
    commentsFiltered: Ember.computed('comments', 'filter', function () {
      if (this.get('filter.value') != 'All') return this.get('comments').filterBy('commentType', this.get('filter.value'));else {
        return this.get('comments');
      }
    }),
    commentsToShow: Ember.computed('comments', 'commentsFiltered', 'showAll', function () {
      if (this.get('showAll')) {
        return this.get('commentsFiltered');
      } else {
        return this.get('commentsFiltered').slice(0, this.get('limit'));
      }
    }),
    numCommentsHidden: Ember.computed('commentsFiltered', 'showAll', function () {
      if (this.get('showAll')) {
        return 0;
      } else {
        return Math.max(this.get('commentsFiltered.length') - this.get('limit'), 0);
      }
    }),
    oneCommentHidden: Ember.computed.equal('numCommentsHidden', 1),
    anyCommentsHidden: Ember.computed.gt('numCommentsHidden', 0),
    actions: {
      toggleShowAll: function toggleShowAll() {
        this.set('showAll', !this.get('showAll'));
      }
    }
  });

  _exports.default = _default;
});