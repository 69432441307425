define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wLnb9nmk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"button\"],[[27,\"component\",[\"bs-button-group/button\"],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[23,[\"type\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"buttonPressed\"],null]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
    }
  });

  _exports.default = _default;
});