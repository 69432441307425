define("workcompass-components/templates/components/wc-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v1WPfYAh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"display\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"containerClass\",\"overlayClass\"],[true,\"wc-modal__container\",\"wc-modal__overlay\"]],{\"statements\":[[4,\"if\",[[23,[\"isWarning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"wc-modal__header wc-modal__header--warning\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"/assets/images/warning-icon.png\"],[11,\"class\",\"wc-modal__icon\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"wc-modal__title\"],[9],[0,\" \"],[1,[21,\"title\"],false],[0,\" \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"wc-modal__header\"],[9],[0,\"\\n        \"],[7,\"h1\"],[11,\"class\",\"wc-modal__title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"wc-modal__body\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"wc-modal__actions-row\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"cancellable\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"wc-button\",null,[[\"label\",\"onClicked\",\"isPrimary\",\"isDisabled\"],[[23,[\"cancelLabel\"]],[27,\"action\",[[22,0,[]],\"cancelClicked\"],null],true,[23,[\"cancelDisabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[27,\"wc-button\",null,[[\"label\",\"id\",\"onClicked\",\"isPrimary\",\"isDisabled\"],[[23,[\"okLabel\"]],[23,[\"buttonID\"]],[27,\"action\",[[22,0,[]],\"okClicked\"],null],false,[23,[\"okDisabled\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-modal.hbs"
    }
  });

  _exports.default = _default;
});