define("workcompass-components/components/rateable-card", ["exports", "workcompass-components/templates/components/rateable-card"], function (_exports, _rateableCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _rateableCard.default,
    selectRating: function selectRating(rating) {
      var val = "".concat(rating);
      this.$('option[value="' + val + '"]').prop('selected', true);
    },
    changeEvent: function changeEvent(event, item) {
      var _this = this;

      if (event.target.className === 'rating__dropdown') {
        item.set('rating', event.target.value);
        item.save().then(function () {
          if (typeof _this.get('onRatingChange') === 'function') {
            _this.get('onRatingChange')();
          }
        });
      }
    }
  });

  _exports.default = _default;
});