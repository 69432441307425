define("workcompass-components/templates/components/form-controls/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LjRD97aC",
    "block": "{\"symbols\":[\"&default\",\"@label\",\"@name\",\"@value\",\"@placeholder\",\"@disabled\",\"@required\",\"&attrs\",\"@type\",\"@onError\"],\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"control-label\"],[12,\"for\",[28,[[22,0,[\"elementId\"]],\"-input\"]]],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[8,\"input\"],[12,\"id\",[28,[[22,0,[\"elementId\"]],\"-input\"]]],[12,\"name\",[22,3,[]]],[12,\"value\",[22,4,[]]],[12,\"placeholder\",[22,5,[]]],[12,\"disabled\",[22,6,[]]],[12,\"required\",[22,7,[]]],[13,8],[12,\"type\",[22,9,[]]],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,10,[]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"error-message\"],[9],[1,[21,\"onError\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/form-controls/input.hbs"
    }
  });

  _exports.default = _default;
});