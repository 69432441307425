define("workcompass-components/templates/components/wc-copy-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htj2d1CN",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"class\",\"value\"],[\"copy-text-input settings-row__input wc-copy-icon-input\",[23,[\"text\"]]]]],false],[0,\"\\n\"],[7,\"span\"],[9],[0,\" \"],[7,\"img\"],[11,\"src\",\"/assets/images/copy-paste1.png\"],[9],[10],[3,\"action\",[[22,0,[]],\"copyText\",[23,[\"index\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-copy-icon.hbs"
    }
  });

  _exports.default = _default;
});