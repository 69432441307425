enifed("ember-template-compiler/lib/system/dasherize-component-name", ["exports", "@ember/-internals/utils"], function (_exports, _utils) {
  "use strict";

  _exports.default = void 0;

  /*
    This diverges from `Ember.String.dasherize` so that`<XFoo />` can resolve to `x-foo`.
    `Ember.String.dasherize` would resolve it to `xfoo`..
  */
  var SIMPLE_DASHERIZE_REGEXP = /[A-Z]/g;
  var ALPHA = /[A-Za-z0-9]/;

  var _default = new _utils.Cache(1000, function (key) {
    return key.replace(SIMPLE_DASHERIZE_REGEXP, function (char, index) {
      if (index === 0 || !ALPHA.test(key[index - 1])) {
        return char.toLowerCase();
      }

      return "-" + char.toLowerCase();
    });
  });

  _exports.default = _default;
});