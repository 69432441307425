define("chat/components/dynamic-hbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: Ember.computed('content', function () {
      return Ember.HTMLBars.compile("\n       ".concat(this.get('content'), "\n     "));
    })
  });

  _exports.default = _default;
});