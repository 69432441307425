define("workcompass-components/components/button-dropdown", ["exports", "workcompass-components/components/trigger-dropdown", "workcompass-components/templates/components/button-dropdown"], function (_exports, _triggerDropdown, _buttonDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _triggerDropdown.default.extend({
    // Component structure
    classNames: ['button-dropdown'],
    layout: _buttonDropdown.default,
    // Computed
    current: Ember.computed('items', 'selected', 'value', 'display', function () {
      if (Ember.isEmpty(this.selected)) {
        return {
          display: 'Please select',
          value: ''
        };
      }

      var key = this.display;
      var valueKey = this.value;
      var selectedValue = this.selected;
      var selected = this.items.find(function (item) {
        return Ember.isEqual(item[valueKey] || '', selectedValue);
      });
      return {
        display: selected[key],
        value: selected[valueKey]
      };
    }),
    options: Ember.computed('items', 'selected', 'value', 'display', function () {
      var key = this.display;
      var valueKey = this.value;
      var selectedValue = this.selected;
      return this.items.filter(function (item) {
        return !Ember.isEqual(item[valueKey] || '', selectedValue);
      }).map(function (item) {
        return {
          display: item[key],
          value: item[valueKey]
        };
      });
    }),
    actions: {
      select: function select(value) {
        if (typeof this.onChange === 'function') {
          this.onChange(value);
        }
      },
      expand: function expand() {
        this.toogle();
      }
    }
  });

  _exports.default = _default;
});