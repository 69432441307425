define("workcompass-components/components/goal-card", ["exports", "workcompass-components/components/rateable-card", "workcompass-components/templates/components/goal-card"], function (_exports, _rateableCard, _goalCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rateableCard.default.extend({
    layout: _goalCard.default,
    ratingChanged: false,
    anyComments: Ember.computed.notEmpty('allComments'),
    milestoneCommentArrays: Ember.computed.alias('goal.milestones.@each.comments'),
    milestoneProgressUpdateArrays: Ember.computed.alias('goal.milestones.@each.progressUpdates'),
    allComments: Ember.computed('goal.{comments.[],ratings.[],milestones.[].progressUpdates.[]}', 'milestoneCommentArrays.[]', 'milestoneProgressUpdateArrays.[]', 'numberOfDeletedMilestoneComments', function () {
      var totalComments = [];
      this.get('goal.comments').forEach(function (c) {
        totalComments.push(c);
      });
      this.get('goal.ratings').forEach(function (r) {
        totalComments.push(r);
      });
      var milestones = this.get('goal.milestones');
      milestones.forEach(function (milestone) {
        milestone.get('comments').forEach(function (c) {
          totalComments.push(c);
        });
        milestone.get('progressUpdates').forEach(function (r) {
          totalComments.push(r);
        });
      });
      return totalComments.sortBy('createdAt').reverse();
    }),
    numberOfDeletedMilestoneComments: 0,
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.selectRating(this.get('goal.rating'));
    },
    change: function change(event) {
      var goal = this.get('goal');
      this.changeEvent(event, goal);
    },
    actions: {
      incrementNumberOfDeletedMilestoneComments: function incrementNumberOfDeletedMilestoneComments() {
        this.incrementProperty('numberOfDeletedMilestoneComments');
      },
      savePercentageComplete: function savePercentageComplete() {
        var _this = this;

        this.get('goal').save().then(function () {
          _this.set('ratingChanged', false);
        });
      },
      checkRatingChange: function checkRatingChange() {
        var changedAttrs = Object.keys(this.get('goal').changedAttributes());

        if (changedAttrs.indexOf('rating') > -1) {
          this.set('ratingChanged', true);
        }
      }
    }
  });

  _exports.default = _default;
});