define("plans/models/staff-member", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    name: _emberData.default.attr('string'),
    needsInvitation: _emberData.default.attr('boolean'),
    noPlan: _emberData.default.attr('boolean'),
    pictureURL: _emberData.default.attr('string'),
    // Relationship
    createPlanTasks: _emberData.default.hasMany('task', {
      polymorphic: true
    }),
    engagementSurvey: _emberData.default.hasMany('task', {
      polymorphic: true
    }),
    inviteTasks: _emberData.default.hasMany('task', {
      polymorphic: true
    }),
    leadershipSurvey: _emberData.default.hasMany('task', {
      polymorphic: true
    }),
    mostRecentPlan: _emberData.default.belongsTo('plan', {
      inverse: null
    }),
    newLeadershipSurvey: _emberData.default.hasMany('task', {
      polymorphic: true
    }),
    plans: _emberData.default.hasMany('plan', {
      inverse: 'staffMember'
    }),
    // Computed
    needsInvitationOrHasNoPlan: Ember.computed('needsInvitation', 'noPlan', function () {
      return this.get('needsInvitation') || this.get('noPlan');
    }),
    // Methods
    invite: function invite(params) {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.invite(this, params);
    }
  });

  _exports.default = _default;
});