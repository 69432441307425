define("organization-settings/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('performance-review-calendars', {
      path: 'performance-review-calendars/:id'
    });
    this.route('engagement-survey');
    this.route('third-parties');
    this.route('organization-goals');
    this.route('division-goals');
    this.route('templates');
    this.route('password_auth');
    this.route('adp-setup');
    this.route('authentication-options/azure-oauth2');
    this.route('divisions');
    this.route('admin/users');
    this.route('users-sync');
    this.route('reports-configuration'); // Errors routes

    this.route('errors', function () {
      this.route('not-authorised');
    });
  });

  _exports.default = _default;
});