define("workcompass-components/components/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Class inheritance for wrapping style
    classNames: ['ember-wc-core']
  });

  _exports.default = _default;
});