define("organization-settings/models/item-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    publishedText: _emberData.default.attr('string'),
    draftText: _emberData.default.attr('string'),
    draftOrdinal: _emberData.default.attr('number'),
    publishedOrdinal: _emberData.default.attr('number'),
    publishedAt: _emberData.default.attr('date'),
    markedForArchivalAt: _emberData.default.attr('date'),
    archivedAt: _emberData.default.attr('date'),
    sectionTemplate: _emberData.default.belongsTo('section-template', {
      inverse: 'itemTemplates'
    }),
    isDraft: Ember.computed('publishedAt', function () {
      return Boolean(this.get('publishedAt')) === false;
    }),
    isPublished: Ember.computed('publishedAt', 'isArchived', function () {
      return this.get('publishedAt') && !this.get('isArchived');
    }),
    isArchived: Ember.computed('archivedAt', function () {
      return Boolean(this.get('archivedAt'));
    }),
    isMarkedForArchival: Ember.computed('markedForArchivalAt', function () {
      return Boolean(this.get('markedForArchivalAt'));
    }),
    revert: function revert() {
      if (!this.get('isArchived')) {
        this.set('draftText', this.get('publishedText'));
        this.set('markedForArchivalAt', null);
      }

      return this;
    }
  });

  _exports.default = _default;
});