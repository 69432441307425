define("organization-settings/controllers/adp-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * This controller gets the credentials for adp if it has consent.
  *
  *
  */
  var _default = Ember.Controller.extend({
    successUri: window.location.href.split('?')[0],
    queryParams: ['consented'],
    consented: false,
    syncro: Ember.observer('adpConfig', 'consented', function () {
      var adp = this.get('adpConfig');

      if (adp) {
        if (this.get('consented')) {
          adp.getCredentials();
        }
      }
    })
  });

  _exports.default = _default;
});