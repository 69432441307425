define("plans/templates/components/question-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QVoyVaEs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"wc-card\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"wc-card-header\",null,[[\"number\",\"item\"],[[23,[\"number\"]],[23,[\"question\"]]]]],false],[0,\"\\n\"],[4,\"wc-card-body\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-1 card__first-col\"],[9],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"col-11 pl-0 card__comment-input\"],[9],[0,\"\\n        \"],[1,[27,\"comment-input\",null,[[\"isDisabled\",\"commentable\",\"placeholderText\"],[[23,[\"isDisabled\"]],[23,[\"question\"]],\"Start typing to discuss this question...\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"anyQuestionComments\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"card__body-row comment-list row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"card__first-col col-1\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-11 p-0\"],[9],[0,\"\\n          \"],[1,[27,\"comment-list\",null,[[\"comments\"],[[23,[\"question\",\"orderedComments\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/components/question-card.hbs"
    }
  });

  _exports.default = _default;
});