define("workcompass-components/templates/components/card-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h9MdauuL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"attachment__link\"],[12,\"href\",[23,[\"attachment\",\"url\"]]],[12,\"title\",[23,[\"attachment\",\"name\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"attachment d-flex\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"attachment__icon align-self-center\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"attachment__info\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"attachment__name\"],[9],[0,\" \"],[1,[27,\"truncate\",[[23,[\"attachment\",\"name\"]]],[[\"limit\"],[8]]],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"attachment__extension\"],[9],[0,\"\\n        \"],[1,[23,[\"attachment\",\"extension\"]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"attachment__size\"],[9],[0,\"\\n        \"],[1,[23,[\"attachment\",\"size\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/card-attachment.hbs"
    }
  });

  _exports.default = _default;
});