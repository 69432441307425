define("organization-settings/controllers/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   *  Array of draft fields that are exempt from validation for being blank
   */
  var BLANK_EXEMPT_FIELDS = ['draftScore'];

  var _default = Ember.Controller.extend({
    queryParams: ['showOverallRatingOptionsModal'],
    notifier: Ember.inject.service(),
    showModal: false,
    showRevertModal: false,
    showOverallRatingOptionsModal: false,
    planCount: '',
    destroyOrRevertMethod: false,
    okLabel: Ember.computed('', function () {
      return "Yes, edit ".concat(this.get('planTemplate.planCount'), " plans");
    }),

    /**
     * Checks if the form is invalid based on draft fields in section templates.
     *
     * @return {Boolean}
     */
    isFormInvalid: Ember.computed('planTemplate.displayableSectionTemplates.@each.' + '{draftName,displayableItemTemplates,displayableRatingOptionTemplates}', 'planTemplate.displayableRatingOptionTemplates.@each.draftLabel', function () {
      return Ember.isEmpty(this.planTemplate) || this.hasInvalidSections() || this.planTemplate.displayableRatingOptionTemplates.some(this.hasInvalidFields);
    }),

    /**
     * Checks if any section or its associated items/rating options are invalid.
     *
     * @returns {Boolean}
     */
    hasInvalidSections: function hasInvalidSections() {
      var _this = this;

      return this.planTemplate.displayableSectionTemplates.some(function (sectionTemplate) {
        return [sectionTemplate].concat(_toConsumableArray(sectionTemplate.displayableItemTemplates), _toConsumableArray(sectionTemplate.displayableRatingOptionTemplates)).some(_this.hasInvalidFields);
      });
    },

    /**
     * Checks if a section or template is invalid based on draft fields.
     *
     * @param {Object} section - The section or template object to check.
     * @return {Boolean}
     */
    hasInvalidFields: function hasInvalidFields(section) {
      return Object.keys(section.toJSON()).filter(function (k) {
        return k.startsWith('draft') && !BLANK_EXEMPT_FIELDS.includes(k);
      }).some(function (field) {
        return !Ember.isPresent(section.get(field));
      });
    },
    showPublishedStatus: false,
    showRevertStatus: false,
    okDisabled: Ember.computed('planCount', 'planTemplate.planCount', function () {
      return parseInt(this.get('planCount')) !== this.get('planTemplate.planCount');
    }),
    sectionTemplateOrdinals: Ember.computed.mapBy('planTemplate.sectionTemplates', 'draftOrdinal'),
    maxSectionTemplateOrdinal: Ember.computed.max('sectionTemplateOrdinals'),
    errorTotalWeight: Ember.computed('planTemplate.sectionTemplates.@each.draftWeight', function () {
      var totalAssignedWeight = this.get('planTemplate.sectionTemplates').filter(function (i) {
        return !isNaN(parseInt(i.get('draftWeight')));
      }).reduce(function (t, i) {
        return t + parseInt(i.get('draftWeight'));
      }, 0);
      return totalAssignedWeight != 100;
    }),
    setPublishedAtAndArchivedAt: function setPublishedAtAndArchivedAt(template) {
      template.set('publishedAt', new Date());

      if (template.get('markedForArchivalAt')) {
        template.set('archivedAt', new Date());
      }
    },
    destroyOrRevert: function destroyOrRevert(template) {
      var promises = [];

      if (template.get('isDraft')) {
        promises.push(template.destroyRecord());
        this.set('destroyOrRevertMethod', true);
      } else {
        promises.push(template.revert().save());
      }
    },
    setItemOrRatingOptionTemplateProperties: function setItemOrRatingOptionTemplateProperties(template, isItemTemplate) {
      var promises = [];
      this.setPublishedAtAndArchivedAt(template);

      if (isItemTemplate) {
        template.set('publishedText', template.get('draftText'));
        template.set('publishedOrdinal', template.get('draftOrdinal'));
      } else {
        template.set('publishedLabel', template.get('draftLabel'));
        template.set('publishedScore', template.get('draftScore'));
      }

      promises.push(template.save());
    },
    setSectionTemplateProperties: function setSectionTemplateProperties(template) {
      this.setPublishedAtAndArchivedAt(template);
      template.set('publishedName', template.get('draftName'));
      template.set('publishedWeight', template.get('draftWeight'));
      template.set('publishedOrdinal', template.get('draftOrdinal'));
      template.set('publishedContributesToPotential', template.get('draftContributesToPotential'));
    },
    setProperties: function setProperties() {
      this.set('planCount', '');
      this.set('showModal', false);
    },
    actions: {
      addNewSection: function addNewSection(type) {
        var plan = this.get('planTemplate');
        var section = this.get('planTemplate.store').createRecord('sectionTemplate', {
          draftName: '',
          draftOrdinal: this.get('maxSectionTemplateOrdinal') + 1,
          draftWeight: 0.0,
          type: type
        });
        plan.get('sectionTemplates').pushObject(section);
        Ember.$('option.div-goal-dropdown__value')[0].selected = 'selected';
      },
      displayModal: function displayModal() {
        this.set('showModal', true);
      },
      editOverallRatingOptions: function editOverallRatingOptions() {
        this.set('showOverallRatingOptionsModal', true);
      },
      cancel: function cancel() {
        this.set('planCount', '');
        this.set('showModal', false);
      },
      publish: function publish() {
        var _this2 = this;

        this.get('notifier').startedSave();
        var promises = [];
        this.get('planTemplate.sectionTemplates').forEach(function (sectionTemplate) {
          _this2.setSectionTemplateProperties(sectionTemplate);

          promises.push(sectionTemplate.save().then(function () {
            sectionTemplate.get('itemTemplates').forEach(function (questionTemplate) {
              _this2.setItemOrRatingOptionTemplateProperties(questionTemplate, true);
            });
            sectionTemplate.get('ratingOptionTemplates').forEach(function (ratingOptionTemplate) {
              _this2.setItemOrRatingOptionTemplateProperties(ratingOptionTemplate, false);
            });
          }));
        });
        this.get('planTemplate.ratingOptionTemplates').forEach(function (ratingOptionTemplate) {
          _this2.setItemOrRatingOptionTemplateProperties(ratingOptionTemplate, false);
        });
        Ember.RSVP.all(promises).then(function () {
          return _this2.get('notifier').finishedSave();
        });
        this.setProperties();
        this.set('showPublishedStatus', true);
      },
      confirmRevert: function confirmRevert() {
        this.set('showRevertModal', true);
      },
      revert: function revert() {
        var _this3 = this;

        this.get('notifier').startedSave();
        var promises = [];
        this.get('planTemplate.sectionTemplates').forEach(function (sectionTemplate) {
          sectionTemplate.get('itemTemplates').forEach(function (itemTemplate) {
            _this3.destroyOrRevert(itemTemplate);
          });
          sectionTemplate.get('ratingOptionTemplates').forEach(function (ratingOptionTemplate) {
            _this3.destroyOrRevert(ratingOptionTemplate);
          });

          _this3.destroyOrRevert(sectionTemplate);
        });
        this.get('planTemplate.ratingOptionTemplates').forEach(function (ratingOptionTemplate) {
          _this3.destroyOrRevert(ratingOptionTemplate);
        });
        this.set('showRevertModal', false);
        Ember.RSVP.all(promises).then(function () {
          return _this3.get('notifier').finishedSave();
        });
        this.set('showRevertStatus', true);
      }
    }
  });

  _exports.default = _default;
});