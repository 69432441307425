define("plans/components/printable-competency", ["exports", "plans/templates/components/printable-competency"], function (_exports, _printableCompetency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _printableCompetency.default,
    classNames: ['printable-plan-item', 'printable-competency']
  });

  _exports.default = _default;
});