define("organization-settings/adapters/templates-surveys-question", ["exports", "organization-settings/adapters/templates-surveys-type-abstract"], function (_exports, _templatesSurveysTypeAbstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _templatesSurveysTypeAbstract.default.extend({
    urlForUpdateRecord: function urlForUpdateRecord(id, modelname, snapshot) {
      var category = snapshot.record.category;

      var surveyType = this._getSurveyType(category.get('surveyType'));

      return "/".concat(this.namespace, "/").concat(surveyType, "/categories/").concat(category.get('id'), "/questions/").concat(id);
    }
  });

  _exports.default = _default;
});