define("workcompass-components/components/form-controls/select", ["exports", "workcompass-components/components/form-controls/selection", "workcompass-components/templates/components/form-controls/select"], function (_exports, _selection, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selection.default.extend({
    layout: _select.default,
    needPlaceHolder: Ember.computed('placeholder', 'value', function () {
      return Ember.isEmpty(this.selected) && !Ember.isEmpty(this.placeholder);
    }),
    options: Ember.computed('sortedOptions', 'display', 'value', 'selected', function () {
      var self = this;
      return (this.sortedOptions || []).map(function (opt) {
        return {
          display: opt[self.display],
          value: opt[self.value],
          selected: opt[self.value] === self.selected
        };
      });
    })
  });

  _exports.default = _default;
});