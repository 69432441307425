define("workcompass-components/components/wc-rating-dropdown", ["exports", "workcompass-components/templates/components/wc-rating-dropdown"], function (_exports, _wcRatingDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcRatingDropdown.default,
    orderedRatingOptions: []
  });

  _exports.default = _default;
});