define("plans/routes/team-bar/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    model: function model(params) {
      return Ember.RSVP.hash({
        plan: this.get('store').findRecord('plan', params.id)
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('plan', models.plan);
      this.controllerFor('team-bar').set('active', models.plan.staffMember);
    },
    setProperties: function setProperties(plan) {
      plan.set('status', 'current');
      plan.set('archivable', plan.get('reopenable'));
      plan.set('reopenable', false);
    },
    actions: {
      reopenPlan: function reopenPlan() {
        var _this = this;

        var plan = this.currentModel.plan;
        plan.review({
          review: 'reopen_plan'
        }).then(function () {
          _this.setProperties(plan);
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});