define("organization-settings/models/question-template", ["exports", "organization-settings/models/item-template"], function (_exports, _itemTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemTemplate.default.extend({});

  _exports.default = _default;
});