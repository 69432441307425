define("workcompass-components/components/calendar-summary", ["exports", "workcompass-components/templates/components/calendar-summary"], function (_exports, _calendarSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _calendarSummary.default,
    classNames: ['hoverbox']
  });

  _exports.default = _default;
});