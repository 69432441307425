define("organization-settings/routes/engagement-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    model: function model() {
      return this.store.query('templates-surveys-category', {
        filter: {
          surveyType: 'EngagementSurvey'
        }
      });
    }
  });

  _exports.default = _default;
});