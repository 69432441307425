define("design-system/templates/components/sliders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XL4sI60N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Sliders \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" A slider is used to select a discrete value. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  In WorkCompass we use a milestone slider to update the progress on a milestone.\\n  It is between 0 and 100.\\n\"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of a milestone slider. It is possible to pass in a progress value and an isDisabled boolean.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    {{milestone-slider progressValue=milestone.progressValue isDisabled=true}}\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[1,[27,\"milestone-slider\",null,[[\"progressValue\",\"isDisabled\"],[[23,[\"milestone\",\"progressValue\"]],true]]],false],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/sliders.hbs"
    }
  });

  _exports.default = _default;
});