define("organization-settings/models/review-phase", ["exports", "organization-settings/models/phase"], function (_exports, _phase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _phase.default.extend({
    label: 'Review'
  });

  _exports.default = _default;
});