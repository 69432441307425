define("plans/components/printable-section", ["exports", "plans/templates/components/printable-section", "plans/mixins/section-items"], function (_exports, _printableSection, _sectionItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionItems.default, {
    layout: _printableSection.default,
    items: Ember.computed.alias('section.orderedItems')
  });

  _exports.default = _default;
});