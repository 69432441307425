define("plans/components/printable-development-need-section", ["exports", "plans/templates/components/printable-development-need-section"], function (_exports, _printableDevelopmentNeedSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _printableDevelopmentNeedSection.default,
    tagName: 'section',
    classNames: ['printable-section', 'printable-development-need-section']
  });

  _exports.default = _default;
});