define("workcompass-components/components/calendar-date-selector", ["exports", "workcompass-components/templates/components/calendar-date-selector"], function (_exports, _calendarDateSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _calendarDateSelector.default
  });

  _exports.default = _default;
});