define("design-system/templates/components/icon-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3UuKNqRM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Icon lines \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass icon lines are used on the plan page in all of the cards. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of icon lines.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <div class='card__first-col col-1'>\\n      <span class='card__body-row-connector card__body-row-connector-top'></span>\\n      <span class='card__body-row-icon rating-row__icon'></span>\\n    </div>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"div\"],[11,\"class\",\"card__first-col col-1\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"card__body-row-connector card__body-row-connector-top\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"card__body-row-icon rating-row__icon\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/icon-lines.hbs"
    }
  });

  _exports.default = _default;
});