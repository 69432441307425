define("organization-settings/controllers/division-goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    displayedDivision: null,
    displayedDivisionGoals: Ember.computed('displayedDivision.@each.divisionGoals', function () {
      return this.get('displayedDivision.divisionGoals');
    }),
    showDialog: false,
    selectedDivision: null,
    selectedOrganizationGoal: null,
    goalDescription: '',
    actions: {
      displayDivision: function displayDivision(divId) {
        this.set('displayedDivision', this.get('store').peekRecord('division', divId));
      },
      addNewGoal: function addNewGoal() {
        this.set('selectedDivision', this.get('displayedDivision'));
        this.set('showDialog', true);
      },
      save: function save() {
        if (this.get('goalBeingEdited')) {
          var divGoal = this.get('goalBeingEdited');
          this.send('setDivGoalProperties', divGoal);
          divGoal.save();
        } else {
          var _divGoal = this.get('store').createRecord('division-goal', {
            description: this.get('goalDescription'),
            division: this.get('selectedDivision'),
            organizationGoal: this.get('selectedOrganizationGoal')
          });

          _divGoal.save();
        }

        this.send('cancel');
      },
      setDivGoalProperties: function setDivGoalProperties(divGoal) {
        divGoal.set('description', this.get('goalDescription'));
        divGoal.set('division', this.get('selectedDivision'));
        divGoal.set('organizationGoal', this.get('selectedOrganizationGoal'));
      },
      cancel: function cancel() {
        this.set('showDialog', false);
        this.set('selectedDivision', null);
        this.set('goalDescription', '');
        this.set('goalBeingEdited', null);
        this.set('selectedOrganizationGoal', null);
      },
      selectDivision: function selectDivision(divId) {
        this.set('selectedDivision', this.get('store').peekRecord('division', divId));
      },
      selectOrgGoal: function selectOrgGoal(orgGoalId) {
        this.set('selectedOrganizationGoal', this.get('store').peekRecord('organization-goal', orgGoalId));
      },
      edit: function edit(divGoal) {
        this.set('goalBeingEdited', divGoal);
        this.set('goalDescription', divGoal.get('description'));
        this.set('selectedDivision', divGoal.get('division'));
        this.set('selectedOrganizationGoal', divGoal.get('organizationGoal'));
        this.set('showDialog', true);
      },
      delete: function _delete(divGoal) {
        divGoal.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});