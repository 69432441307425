define("plans/models/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    plan: _emberData.default.belongsTo('plan'),
    taskType: _emberData.default.attr('string'),
    query: _emberData.default.attr('string'),
    mailedAt: _emberData.default.attr('date'),
    cycleId: _emberData.default.attr('string'),
    phaseId: _emberData.default.attr('string'),
    subjectType: _emberData.default.attr('string'),
    subjectId: _emberData.default.attr('number'),
    onBehalfOf: _emberData.default.attr('string'),
    onBehalfOfId: _emberData.default.attr('number')
  });

  _exports.default = _default;
});