define("workcompass-components/components/wc-button", ["exports", "workcompass-components/templates/components/wc-button"], function (_exports, _wcButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcButton.default,
    tagName: 'button',
    classNames: ['wc-button'],
    classNameBindings: ['isPrimary:wc-button--primary', 'isSmall:wc-button--small', 'isDisabled:disabled'],
    attributeBindings: ['isDisabled:disabled'],
    //attributes
    label: null,
    //style
    isDisabled: false,
    isPrimary: false,
    isSmall: false,

    /**
     * Respond to button clicked
     * @param {HTMLEvent} evt
     */
    click: function click(evt) {
      var onClicked = this.get('onClicked');

      if (typeof onClicked === 'function') {
        onClicked(evt);
      }
    }
  });

  _exports.default = _default;
});