define("workcompass-components/components/wc-card-header", ["exports", "workcompass-components/templates/components/wc-card-header"], function (_exports, _wcCardHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcCardHeader.default,
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    showAddFeedbackModal: false,
    showKebabMenu: true,
    feedBackEmail: null,
    hasValidEmail: Ember.computed.match('feedBackEmail', /^.+@.+\..+$/),
    error: null,
    actions: {
      redirectToSmartPlan: function redirectToSmartPlan() {
        var planId = this.get('item.section.plan.id');
        window.location.replace("/app/plans/".concat(planId));
      },
      addFeedback: function addFeedback(dropdown) {
        dropdown.actions.close();
        this.set('showAddFeedbackModal', true);
      },
      saveFeedback: function saveFeedback() {
        var _this = this;

        this.get('store').createRecord('feedbackRequest', {
          item: this.get('item'),
          email: this.get('feedBackEmail')
        }).save().then(function () {
          _this.set('feedBackEmail', null);

          _this.set('error', null);

          _this.set('showAddFeedbackModal', false);
        }).catch(function () {
          if (!_this.get('hasValidEmail')) {
            _this.set('error', 'The email is not valid or is empty.');
          } else {
            _this.set('error', 'error.');
          }
        });
      },
      cancel: function cancel() {
        this.set('feedBackEmail', null);
        this.set('showAddFeedbackModal', false);
      }
    }
  });

  _exports.default = _default;
});