define("workcompass-components/templates/components/wc-display-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kAkI5LLf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"anyComments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"card__body-row comment-list row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card__first-col col-1\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-11 p-0\"],[9],[0,\"\\n      \"],[1,[27,\"comment-list\",null,[[\"comments\",\"onDelete\",\"emailCoachingFeedback\"],[[23,[\"allComments\"]],[23,[\"incrementNumberOfDeletedMilestoneComments\"]],[23,[\"emailCoachingFeedback\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-display-comments.hbs"
    }
  });

  _exports.default = _default;
});