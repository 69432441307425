define("design-system/controllers/components/color-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    colorNames: [{
      colorName: 'blue-gray',
      colorHex: '#adc2c0'
    }, {
      colorName: 'dark-gray',
      colorHex: '#4a4a4a'
    }, {
      colorName: 'mid-gray',
      colorHex: '#acc2c0'
    }, {
      colorName: 'light-gray',
      colorHex: '#f8f8f8'
    }, {
      colorName: 'salmon',
      colorHex: '#f37e5c'
    }, {
      colorName: 'white',
      colorHex: '#fff'
    }, {
      colorName: 'darker-gray',
      colorHex: '#333333'
    }, {
      colorName: 'gray',
      colorHex: '#757575'
    }, {
      colorName: 'error-color',
      colorHex: '#fb3500'
    }, {
      colorName: 'success-color',
      colorHex: '#34c62a'
    }, {
      colorName: 'onepage-card-header-bg-color',
      colorHex: '#fafbfb'
    }, {
      colorName: 'onepage-card-detail-lightgray-color',
      colorHex: '#acacac'
    }, {
      colorName: 'onepage-attachment-bg-color',
      colorHex: '#efefef'
    }, {
      colorName: 'onepage-milestone-due-dark-color',
      colorHex: '#34c62a'
    }, {
      colorName: 'onepage-milestone-due-light-color',
      colorHex: '#f1faf0'
    }, {
      colorName: 'onepage-comment-border',
      colorHex: '#d8d8d8'
    }, {
      colorName: 'onepage-orange',
      colorHex: '#f37e5c'
    }, {
      colorName: 'onepage-bg-comment-bubble',
      colorHex: '#f1f2f5'
    }, {
      colorName: 'onepage-comment-header-gray',
      colorHex: '#9a9a9a'
    }, {
      colorName: 'onepage-comment-type-color-comment',
      colorHex: '#ffe289'
    }, {
      colorName: 'onepage-comment-type-color-coaching-feedback',
      colorHex: '#b7bbfc'
    }, {
      colorName: 'onepage-comment-type-color-change',
      colorHex: '#f37e5c'
    }, {
      colorName: 'onepage-review-complete-color',
      colorHex: '#2bb01a'
    }, {
      colorName: 'onepage-review-complete-instruction-color',
      colorHex: '#929791'
    }, {
      colorName: 'onepage-grayed-out-input-slider',
      colorHex: '#d1d1d1'
    }, {
      colorName: 'chat-box__chat-bubble-bg-color',
      colorHex: '#f7f8f9'
    }, {
      colorName: 'chat-box__chat-bubble-ending-bg-color',
      colorHex: '#49da85'
    }, {
      colorName: 'chat-box__chat-bubble-ending-bg-color-hover',
      colorHex: '#dddede'
    }, {
      colorName: 'input-bg-color',
      colorHex: '#eef3f2'
    }, {
      colorName: 'bottom-separator-color',
      colorHex: '#979797'
    }, {
      colorName: 'buttom-bg-color',
      colorHex: '#adc2c0'
    }, {
      colorName: 'buttom-bg-color-primary',
      colorHex: '#f37e5c'
    }]
  });

  _exports.default = _default;
});