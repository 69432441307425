define("design-system/templates/components/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tfsYuSVw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Spinner \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" A spinner is used to show that a page is loading. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass a spinner is used when smart plan is loading. We also want to add this to one page in the future. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" Example 1 shows a simple example of a spinner, this code is used in the loading.hbs page. We use an addon for this. \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <div class='loading-container'>\\n      {{spin-spinner lines=13 length=20 width=10}}\\n    </div>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"div\"],[11,\"class\",\"loading-container\"],[9],[0,\"\\n  \"],[1,[27,\"spin-spinner\",null,[[\"lines\",\"length\",\"width\"],[13,20,10]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/spinner.hbs"
    }
  });

  _exports.default = _default;
});