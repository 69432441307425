define("plans/components/printable-comment-or-change", ["exports", "plans/templates/components/printable-comment-or-change", "plans/components/comment-type"], function (_exports, _printableCommentOrChange, _commentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _commentType.default.extend({
    layout: _printableCommentOrChange.default,
    commentTypeClassName: Ember.computed('item.commentType', function () {
      var commentType = this.get('item.commentType');
      return this.commentTypeClass(commentType);
    }),
    label: Ember.computed('item.commentType', function () {
      if (this.get('item.commentType')) {
        return this.get('item.commentType');
      } else {
        return 'Change';
      }
    })
  });

  _exports.default = _default;
});