define("organization-settings/models/division", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    managed: _emberData.default.attr('boolean'),
    divisionGoals: _emberData.default.hasMany('division-goal'),
    users: _emberData.default.hasMany('user', {
      inverse: 'division'
    }),
    divisionalAdmins: _emberData.default.hasMany('user', {
      inverse: 'managedDivisions'
    })
  });

  _exports.default = _default;
});