define("plans/templates/components/printable-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2hQ+d6HG",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"section\"],[11,\"class\",\"printable-section\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"wc-header\"],[9],[1,[27,\"sn\",[[23,[\"section\",\"name\"]]],[[\"pluralize\",\"capitalize\"],[true,true]]],false],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredItems\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"printable-item\",null,[[\"item\",\"onlyApproved\",\"index\",\"usesPercentageGoalRatings\",\"shouldShowOtherPartyRating\",\"revieweeCanRate\"],[[22,1,[]],[23,[\"onlyApproved\"]],[22,2,[]],[23,[\"usesPercentageGoalRatings\"]],[23,[\"shouldShowOtherPartyRating\"]],[23,[\"revieweeCanRate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"printable-plan__section-empty-info printable-plan--muted-text\"],[9],[0,\"\\n      No \"],[1,[27,\"sn\",[[23,[\"section\",\"name\"]]],[[\"pluralize\",\"capitalize\"],[true,false]]],false],[0,\" on this plan\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/components/printable-section.hbs"
    }
  });

  _exports.default = _default;
});