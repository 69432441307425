define("organization-settings/controllers/password-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Flag indicate if password
     * is mandatory. If no other auth
     * is enabled, password auth is
     * required and cannot be changed.
     */
    mandatory: Ember.computed('registry', function () {
      return this.registry.auth.needLogin;
    }),

    /**
     * Flag indicate if password auth
     * is enabled
     */
    enabled: Ember.computed('registry', function () {
      return Ember.isEmpty(this.registry.auth.passwordAuthEnabled) ? true : this.registry.auth.passwordAuthEnabled;
    }),
    actions: {
      /**
       * Change the state and save
       * @param {Event}   evt   Trigger event
       */
      changeState: function changeState(evt) {
        this.registry.update('password_auth_enabled', evt.target.checked, 'auth');
      }
    }
  });

  _exports.default = _default;
});