define("workcompass-components/components/form-controls/control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-controls'],
    classNameBindings: ['disabled:disabled', 'onError:invalid']
  });

  _exports.default = _default;
});