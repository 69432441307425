define("organization-settings/controllers/editable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    propName: 'name',
    itemBeingEdited: null,
    itemText: '',
    showDialog: false,
    orderedItems: Ember.computed.sort('model', 'itemOrdering'),

    /**
     * Get the sorting order based on the `propName`
     */
    itemOrdering: Ember.computed('propName', function () {
      return ["".concat(this.propName, ":asc")];
    }),
    actions: {
      /**
       * Open dialog to add/edit item
       */
      addNewItem: function addNewItem() {
        this.set('showDialog', true);
      },

      /**
       * Delete an item
       *
       * @param {*} item
       */
      delete: function _delete(item) {
        item.destroyRecord();
      },

      /**
       * Open dialog and defined the editable item
       *
       * @param {*} item
       */
      edit: function edit(item) {
        this.set('itemBeingEdited', item);
        this.set('itemText', item.get(this.propName));
        this.set('showDialog', true);
      },

      /**
       * Save or create new record
       *
       * @param {String} text
       */
      save: function save(text) {
        var item = this.itemBeingEdited;

        if (!item) {
          item = this.store.createRecord(this.model.modelName);
        }

        item.set(this.propName, text);
        item.save();
        this.send('reset');
      },

      /**
       * Set the editable properties and the state of the dialog
       */
      reset: function reset() {
        this.set('showDialog', false);
        this.set('itemBeingEdited', null);
        this.set('itemText', '');
      }
    }
  });

  _exports.default = _default;
});