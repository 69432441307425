define("workcompass-components/components/comment-input", ["exports", "workcompass-components/templates/components/comment-input"], function (_exports, _commentInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _commentInput.default,
    store: Ember.inject.service(),
    onCreate: function onCreate() {},
    showChatBotIcon: false,
    hidePrivacyButton: false,
    private: false,
    notEmpty: Ember.computed.notEmpty('commentText'),
    isEditingMilestoneFeedback: Ember.computed.and('isInputForMilestoneFeedback', 'hasMilestoneFeedback'),
    isInputForMilestoneFeedback: Ember.computed('milestoneForFeedback', function () {
      return this.get('commentable.constructor.modelName') == 'milestone' && this.get('commentable.id') === this.get('milestoneForFeedback.id');
    }),
    hasMilestoneFeedback: Ember.computed.notEmpty('milestoneFeedbackText'),
    commentText: Ember.computed('comment.text', 'isInputForMilestoneFeedback', 'milestoneFeedbackText', function () {
      var comment = this.get('comment');

      if (comment) {
        return comment.get('text');
      } else if (this.get('isEditingMilestoneFeedback')) {
        return this.get('milestoneFeedbackText');
      } else {
        return '';
      }
    }),
    // Property to get the main mascot
    mascot: Ember.computed('', function () {
      var mascot = this.store.peekRecord('setting', 'chat-mascot');

      if (mascot) {
        return mascot.value;
      }

      return '/assets/images/blinky-chat-bot.gif';
    }),
    // Property to get the outlined mascot
    mascotOutlined: Ember.computed('', function () {
      var mascot = this.store.peekRecord('setting', 'chat-mascot-outlined');

      if (mascot) {
        return "url(".concat(mascot.value, ")");
      }

      return '';
    }),
    actions: {
      createComment: function createComment(commentText) {
        var _this = this;

        if (!Ember.isBlank(commentText)) {
          var commentType = '';

          if (this.get('milestoneFeedbackText')) {
            commentType = 'Coaching feedback';
          } else if (this.get('commentable.constructor.modelName') === 'milestone') {
            commentType = 'Progress update';
          } else {
            commentType = 'Comment';
          }

          var comment = this.get('store').createRecord('comment', {
            text: commentText,
            private: this.get('private'),
            commentableType: this.get('commentable.constructor.modelName'),
            commentableId: this.get('commentable.id'),
            createdAt: new Date(),
            commentType: commentType
          });
          comment.save().then(function () {
            _this.get('commentable.comments').pushObject(comment);

            _this.get('onCreate')();

            Ember.$('.comment-input__text').val('');

            _this.set('commentText', '');

            _this.set('private', false);
          });
        }
      },
      updateComment: function updateComment(commentText) {
        if (!Ember.isBlank(commentText)) {
          this.set('comment.text', commentText);
          this.set('comment.private', this.get('private'));
          this.get('comment').save(); // => PATCH to '/posts/1'

          this.get('afterUpdate')();
        }
      },
      togglePrivacy: function togglePrivacy() {
        if (!this.get('isDisabled')) {
          this.set('private', !this.get('private'));
        }
      },
      chatBotClicked: function chatBotClicked(milestone) {
        if (!this.get('isDisabled')) {
          this.get('showCoachingAssistant')(milestone);
        }

        this.set('displayPrompt', false);
      },
      showPrompt: function showPrompt() {
        if (!this.get('shouldShowCoachingAssistant') && !this.get('isEditingMilestoneFeedback')) {
          this.set('displayPrompt', true);
        }
      },
      closeChatPrompt: function closeChatPrompt() {
        this.set('displayPrompt', false);
      }
    }
  });

  _exports.default = _default;
});