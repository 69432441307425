define("workcompass-components/helpers/even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.even = even;
  _exports.default = void 0;

  function even(params
  /*, hash*/
  ) {
    return params[0] % 2 == 0;
  }

  var _default = Ember.Helper.helper(even);

  _exports.default = _default;
});