define("workcompass-components/components/trigger-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Component structure
    classNames: ['ember-wc-core'],
    // Properties
    isExpanded: false,
    // Services
    eventHandler: Ember.inject.service(),
    // Computed

    /**
     * Get the principal application wrapper
     */
    ownerElement: Ember.computed(function () {
      // TODO: Move service window into addon component
      // get the ownerElement from service
      return this.element.ownerDocument.querySelector('.ember-application') || this.element.ownerDocument;
    }),
    // Native events
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.ownerElement) {
        var closeNavHandler = this.get('close').bind(this); // TODO: Replace with addEventListener in > 3.8

        this.eventHandler.addListener(this.ownerElement, "click.".concat(this.element.id), closeNavHandler);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.ownerElement) {
        this.eventHandler.removeListener(this.ownerElement, "click.".concat(this.element.id));
      }
    },
    // Methods
    toogle: function toogle() {
      this.toggleProperty('isExpanded');
    },
    open: function open() {
      this.set('isExpanded', true);
    },
    close: function close(evt) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this.get('isExpanded') && this.element !== evt.target && !Array.from(this.element.children).includes(evt.target)) {
        evt.stopPropagation();
        this.set('isExpanded', false);
      }
    },
    actions: {
      /**
       * Trigger when add element is clicked
       * @param {DOM Event}   evt   Event send by DOM
       */
      add: function add(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if (typeof this.onAdd === 'function') {
          this.onAdd();
        }

        this.toogle();
      }
    }
  });

  _exports.default = _default;
});