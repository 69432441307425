define("workcompass-components/templates/components/milestone-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kX2XHgW8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"slider\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"slider__labels d-flex justify-content-between\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"slider__label\"],[9],[0,\"0\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"slider__label\"],[9],[0,\"25\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"slider__label\"],[9],[0,\"50\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"slider__label\"],[9],[0,\"75\"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"slider__label\"],[9],[0,\"100\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"slider__range\"],[12,\"disabled\",[21,\"isDisabled\"]],[11,\"min\",\"0\"],[11,\"max\",\"100\"],[12,\"value\",[21,\"progressValue\"]],[11,\"type\",\"range\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/milestone-slider.hbs"
    }
  });

  _exports.default = _default;
});