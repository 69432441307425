define("organization-settings/components/save-with-notification", ["exports", "organization-settings/templates/components/save-with-notification"], function (_exports, _saveWithNotification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    layout: _saveWithNotification.default,
    notifier: Ember.inject.service(),

    /**
     * Stores the validation state of each required input.
     */
    inputStates: {},
    saveProxy: function saveProxy(model) {
      var _this = this;

      this.get('notifier').startedSave();
      model.save().finally(function () {
        _this.get('notifier').finishedSave();
      });
    },

    /**
     * Lifecycle hook that is called after the component has been
     * inserted into the DOM. Initializes input states by validating
     * required inputs.
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var states = _toConsumableArray(this.element.querySelectorAll('input[required]')).reduce(function (acc, input) {
        acc[input.name] = {
          isValid: input.reportValidity(),
          message: input.validationMessage || ''
        };
        return acc;
      }, {});

      this.set('inputStates', states);
    },
    actions: {
      /**
       * Updates the validation state of the changed input.
       *
       * @param {Event} event - The input change event.
       */
      handleInputChange: function handleInputChange(event) {
        var input = event.target;
        this.set("inputStates.".concat(input.name), {
          isValid: input.reportValidity(),
          message: input.validationMessage || ''
        });
        this.notifyPropertyChange('inputStates');
      }
    }
  });

  _exports.default = _default;
});