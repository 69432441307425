define("organization-settings/routes/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      return this.get('store').findAll('plan-template');
    },
    setupController: function setupController(controller, model) {
      controller.set('planTemplate', model.get('firstObject'));
    }
  });

  _exports.default = _default;
});