define("workcompass-components/components/wc-kebab-menu", ["exports", "workcompass-components/templates/components/wc-kebab-menu"], function (_exports, _wcKebabMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcKebabMenu.default,
    options: []
  });

  _exports.default = _default;
});