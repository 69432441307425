define("workcompass-components/components/controls/button", ["exports", "workcompass-components/components/base", "workcompass-components/templates/components/controls/button"], function (_exports, _base, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    layout: _button.default,
    tagName: 'button',
    classNameBindings: ['emphasis']
  });

  _exports.default = _default;
});