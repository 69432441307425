define("organization-settings/models/adp-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * This model gets the credentials for adp.
  *
  *
  */
  var _default = _emberData.default.Model.extend({
    sharedSecret: _emberData.default.attr('string'),
    requiresConsent: _emberData.default.attr('boolean'),
    getCredentials: function getCredentials(params) {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.getCredentials(this, params);
    }
  });

  _exports.default = _default;
});