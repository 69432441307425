define("organization-settings/routes/division-goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        orgGoals: this.get('store').findAll('organization-goal'),
        divisions: this.get('store').findAll('division')
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('orgGoals', models.orgGoals);
      controller.set('currentOrgGoals', models.orgGoals.filter(function (og) {
        return !og.get('isArchived');
      }));
      var managedDivisions = models.divisions.filter(function (d) {
        return d.managed;
      });
      controller.set('divisions', managedDivisions);
      controller.set('displayedDivision', managedDivisions.get('firstObject'));
    }
  });

  _exports.default = _default;
});