define("organization-settings/routes/registry-router", ["exports", "organization-settings/routes/abstract-router"], function (_exports, _abstractRouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractRouter.default.extend({
    // Service required by most of the settings routes
    registry: Ember.inject.service(),
    // Define the registry to the controller
    setupController: function setupController(controller) {
      controller.set('registry', this.registry);
    }
  });

  _exports.default = _default;
});