define("plans/controllers/team-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    actions: {
      /**
       * Redirect using transition
       * @param {Object}  {route|model}  Params for transition
       */
      redirectTo: function redirectTo(_ref) {
        var route = _ref.route,
            model = _ref.model;
        this.transitionToRoute(route, model);
      }
    }
  });

  _exports.default = _default;
});