define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jwjs/XDp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"inDom\"]]],null,{\"statements\":[[4,\"bs-popover/element\",null,[[\"id\",\"parent\",\"placement\",\"fade\",\"showHelp\",\"title\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[23,[\"overlayId\"]],[22,0,[]],[23,[\"placement\"]],[23,[\"fade\"]],[23,[\"showHelp\"]],[23,[\"title\"]],[23,[\"class\"]],[23,[\"_renderInPlace\"]],[23,[\"triggerTargetElement\"]],[23,[\"autoPlacement\"]],[23,[\"viewportElement\"]],[23,[\"viewportPadding\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
    }
  });

  _exports.default = _default;
});