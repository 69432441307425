define("workcompass-components/components/wc-inline-notice", ["exports", "workcompass-components/templates/components/wc-inline-notice"], function (_exports, _wcInlineNotice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component for displaying notice messages.
   *
   * This component conditionally renders an notice message based on the
   * `visible` property. When `visible` is `true`, it displays the notice
   * message inline provided through the `message` property. The component is
   * designed to be reusable across different parts of the application
   * where notice messages need to be shown.
   */
  var _default = Ember.Component.extend({
    layout: _wcInlineNotice.default,
    attributeBindings: ['dataTest:data-test', 'style'],
    classNames: ['wc-inline-notice'],
    classNameBindings: ['typeClass'],
    dataTest: 'wc-inline-notice',
    message: 'An error has occurred.',
    tagName: 'span',
    type: 'error',
    visible: false,

    /**
     * Computes the path to the icon image based on the `type` property.
     */
    iconPath: Ember.computed('type', function () {
      return "/assets/images/".concat(this.get('type'), "-icon.png");
    }),

    /**
     * Computed property to control visibility in the template via inline styles.
     */
    style: Ember.computed('visible', function () {
      return "visibility: ".concat(this.visible ? 'visible' : 'hidden');
    }),

    /**
     * Compute the class name based on the `type` property
     */
    typeClass: Ember.computed('type', function () {
      return "wc-inline-notice--".concat(this.get('type'));
    })
  });

  _exports.default = _default;
});