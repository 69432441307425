define("organization-settings/routes/third-parties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Services
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('third-party');
    }
  });

  _exports.default = _default;
});