define("workcompass-components/components/development-need-card", ["exports", "workcompass-components/templates/components/development-need-card"], function (_exports, _developmentNeedCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _developmentNeedCard.default,
    anyDevelopmentNeedComments: Ember.computed.notEmpty('developmentNeed.comments')
  });

  _exports.default = _default;
});