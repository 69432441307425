define("workcompass-components/components/wc-card-body", ["exports", "workcompass-components/templates/components/wc-card-body"], function (_exports, _wcCardBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcCardBody.default,
    tagName: 'div',
    classNames: ['wc-card__body'],
    classNameBindings: ['noHead:card__body--no-header'],
    //style
    noHead: false
  });

  _exports.default = _default;
});