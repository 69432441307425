define("workcompass-components/components/card-details", ["exports", "workcompass-components/templates/components/card-details"], function (_exports, _cardDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _cardDetails.default,
    showSupportedGoal: false,
    isExpanded: false,
    nothingToShowWithWeights: Ember.computed.and('noSupportedGoal', 'noNotes', 'noDevelopmentAction', 'noSuccessMeasure', 'noAttachments', 'noExternalReviewers', 'noWeights'),
    nothingToShowWithoutWeights: Ember.computed.and('noSupportedGoal', 'noNotes', 'noDevelopmentAction', 'noSuccessMeasure', 'noAttachments', 'noExternalReviewers'),
    nothingToShow: Ember.computed('nothingToShowWithWeights', 'nothingToShowWithoutWeights', function () {
      if (this.get('showWeight')) {
        return this.get('nothingToShowWithWeights');
      } else {
        return this.get('nothingToShowWithoutWeights');
      }
    }),
    supportedGoalCount: Ember.computed('noSupportedGoal', function () {
      return this.get('noSupportedGoal') ? 0 : 1;
    }),
    noNotes: Ember.computed.empty('notes'),
    noDevelopmentAction: Ember.computed.empty('developmentAction'),
    noSuccessMeasure: Ember.computed.empty('successMeasure'),
    noExternalReviewers: Ember.computed.empty('item.feedbackRequests'),
    anyNotesOrDevFields: Ember.computed('noNotes', 'noDevelopmentAction', 'noSuccessMeasure', function () {
      return !this.get('noNotes') || !this.get('noDevelopmentAction') || !this.get('noSuccessMeasure');
    }),
    notesAndDevFieldsCount: Ember.computed('noNotes', 'noDevelopmentAction', 'noSuccessMeasure', function () {
      var notesCount = this.get('noNotes') ? 0 : 1;
      var developmentActionCount = this.get('noDevelopmentAction') ? 0 : 1;
      var successMeasureCount = this.get('noSuccessMeasure') ? 0 : 1;
      return notesCount + developmentActionCount + successMeasureCount;
    }),
    noAttachments: Ember.computed.empty('attachments'),
    noSupportedGoal: Ember.computed.none('supportedGoal.description'),
    noWeights: Ember.computed.equal('item.plan.isWeightAssignedByUser', false),
    actions: {
      showDetails: function showDetails() {
        this.set('isExpanded', true);
      },
      hideDetails: function hideDetails() {
        this.set('isExpanded', false);
      }
    }
  });

  _exports.default = _default;
});