define("workcompass-components/components/other-party-rating", ["exports", "workcompass-components/templates/components/other-party-rating"], function (_exports, _otherPartyRating) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _otherPartyRating.default,
    usesPercentageGoalRatings: false,
    getLabel: function getLabel(staffMemberOrLatestReviewerRatingScore) {
      var rating = this.get('ratingOptions').filter(function (opt) {
        return opt.get('score') === staffMemberOrLatestReviewerRatingScore;
      })[0];

      if (rating) {
        return rating.get('label');
      }
    },
    getText: function getText(rating, label) {
      if (this.get('usesPercentageGoalRatings')) {
        return rating === null ? 'Not yet rated' : "".concat(rating, "%");
      } else {
        return label;
      }
    },
    ratingLabel: Ember.computed('ratingOptions.@each.label', 'model.otherPartyRating', function () {
      return this.getLabel(this.get('model.otherPartyRating'));
    }),
    textForRating: Ember.computed('model.otherPartyRating', 'useNumericRating', 'ratingLabel', function () {
      return this.getText(this.get('model.otherPartyRating'), this.get('ratingLabel'));
    })
  });

  _exports.default = _default;
});