define("workcompass-components/components/message-box", ["exports", "workcompass-components/templates/components/message-box"], function (_exports, _messageBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _messageBox.default,
    classNames: ['messagebox'],
    classNameBindings: ['type']
  });

  _exports.default = _default;
});