define("workcompass-components/components/workcompass-calendar", ["exports", "workcompass-components/templates/components/workcompass-calendar"], function (_exports, _workcompassCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _workcompassCalendar.default,
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    actions: {
      onClickPrevYear: function onClickPrevYear() {
        this.get('onClickPrevYear')();
      },
      onClickThisYear: function onClickThisYear() {
        this.get('onClickThisYear')();
      },
      onClickNextYear: function onClickNextYear() {
        this.get('onClickNextYear')();
      },
      onClickCycle: function onClickCycle() {}
    }
  });

  _exports.default = _default;
});