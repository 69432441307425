define("chat/models/response-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    options: _emberData.default.hasMany('response-option', {
      inverse: 'fromResponsePart'
    }),
    content: _emberData.default.attr('string'),
    variableName: _emberData.default.attr('string'),
    nextResponsePartId: _emberData.default.attr('string'),
    canBeCopied: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });

  _exports.default = _default;
});