define("organization-settings/routes/reports-configuration", ["exports", "organization-settings/routes/registry-router"], function (_exports, _registryRouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _registryRouter.default.extend();

  _exports.default = _default;
});