define("workcompass-components/templates/components/wc-rating-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rMF5FM0",
    "block": "{\"symbols\":[\"ratingOptionTemplate\"],\"statements\":[[7,\"select\"],[11,\"class\",\"wc-rating-dropdown__dropdown\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"orderedRatingOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[11,\"class\",\"wc-rating-dropdown__value\"],[12,\"value\",[22,1,[\"score\"]]],[9],[1,[22,1,[\"draftLabel\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-rating-dropdown.hbs"
    }
  });

  _exports.default = _default;
});