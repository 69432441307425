define("plans/models/progress-update-text", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    progressUpdateText: function progressUpdateText(valueOrText, isProgressUpdate) {
      var description = this.get('milestone.description');

      if (isProgressUpdate) {
        return "Progress updated to \"".concat(valueOrText, "%\" for milestone \"").concat(description, "\"");
      } else {
        return "".concat(valueOrText, " for milestone \"").concat(description, "\"");
      }
    }
  });

  _exports.default = _default;
});