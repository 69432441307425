define("organization-settings/templates/errors/not-authorised", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OO+dmjem",
    "block": "{\"symbols\":[],\"statements\":[[5,\"message-box\",[[11,\"data-test\",\"warning-not-authorised\"]],[[\"@type\"],[\"warning\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\"],[9],[0,\"You are not authorized to access this page.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/errors/not-authorised.hbs"
    }
  });

  _exports.default = _default;
});