define("organization-settings/models/templates-surveys-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // HasMany
    questions: _emberData.default.hasMany('templates-surveys-question', {
      inverse: 'category'
    }),
    // Attributes
    name: _emberData.default.attr('string'),
    surveyType: _emberData.default.attr('string')
  });

  _exports.default = _default;
});