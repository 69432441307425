define("organization-settings/templates/admin/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d24iM0Pn",
    "block": "{\"symbols\":[],\"statements\":[[5,\"wc-card\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Bulk actions\"],[10],[0,\"\\n  \"],[5,\"wc-button\",[[11,\"title\",\"Resend invitation to any inactive users\"],[11,\"data-test\",\"reinvite-users\"]],[[\"@onClicked\",\"@isPrimary\"],[[27,\"action\",[[22,0,[]],\"openConfirmation\"],null],\"true\"]],{\"statements\":[[0,\"\\n    Resend invitation\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"notice\"]]],null,{\"statements\":[[0,\"  \"],[5,\"wc-notice\",[[11,\"data-test\",\"notice-message\"]],[[\"@style\"],[[22,0,[\"notice\",\"style\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[22,0,[\"notice\",\"message\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"dialog\",[],[[\"@header\",\"@subheader\",\"@mode\",\"@cancel\",\"@open\",\"@onClose\",\"@onConfirm\"],[\"Send invitation?\",\"Are you sure you want to resend invitation to all users who have not logged in to WorkCompass?\",\"Send Invitation\",\"true\",[21,\"sendInviteDialogOpened\"],[27,\"action\",[[22,0,[]],\"onDialogClose\"],null],[27,\"action\",[[22,0,[]],\"sendInvitations\"],null]]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/admin/users.hbs"
    }
  });

  _exports.default = _default;
});