define("organization-settings/routes/adp-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * This route uses the setupController hook to set the model property of the
  * associated controller to the route handler's model.
  *
  */
  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      return this.get('store').findAll('adp-config');
    },
    setupController: function setupController(controller, model) {
      controller.set('adpConfig', model.get('firstObject'));
    }
  });

  _exports.default = _default;
});