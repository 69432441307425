define("workcompass-components/components/comment-row", ["exports", "workcompass-components/templates/components/comment-row", "plans/components/comment-type"], function (_exports, _commentRow, _commentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_COMMENTER_PICTURE_URL = '/avatar.png';
  var DEFAULT_COMMENTER_NAME = 'Deleted user';

  var _default = _commentType.default.extend({
    layout: _commentRow.default,
    userIsLoggedIn: Ember.computed.alias('session.userIsLoggedIn'),
    private: Ember.computed.alias('comment.private'),
    isEditing: false,
    authorisedToEditAllComments: false,
    canModifyComment: Ember.computed('comment.commenter.id', 'session.currentUser.accountId', function () {
      if (this.get('authorisedToEditAllComments')) {
        return this.get('comment.commentType') === 'Comment';
      } else {
        return this.get('comment.commenter.id') === this.get('session.currentUser.accountId').toString();
      }
    }),
    hideEditComment: Ember.computed('comment.commenter.id', 'session.currentUser.accountId', function () {
      if (this.get('authorisedToEditAllComments')) {
        return true;
      } else {
        return false;
      }
    }),
    session: Ember.inject.service('session'),
    classNames: ['comment-list__comment-row d-flex'],
    classNameBindings: ['private:comment-list__comment-row--private'],
    commentTypeClassName: Ember.computed('comment.commentType', function () {
      var commentType = this.get('comment.commentType');
      return this.commentTypeClass(commentType);
    }),
    commenterName: Ember.computed('comment.user', function () {
      var name = this.get('comment.user.name');
      return Ember.isEmpty(name) ? DEFAULT_COMMENTER_NAME : name;
    }),
    commenterPictureURL: Ember.computed('comment.user', function () {
      var pictureURL = this.get('comment.user.pictureURL');
      return Ember.isEmpty(pictureURL) ? DEFAULT_COMMENTER_PICTURE_URL : pictureURL;
    }),
    actions: {
      editComment: function editComment() {
        this.set('isEditing', true);
      },
      deleteComment: function deleteComment() {
        var _this = this;

        if (this.get('altDeleteComment')) {
          this.get('altDeleteComment')();
        } else {
          this.get('comment').destroyRecord().then(function (comment) {
            if (comment.get('commentableType') === 'Milestone') {
              _this.get('onDelete')();
            }
          });
        }
      },
      stopEditing: function stopEditing() {
        this.set('isEditing', false);
      },
      sendCoachingFeedback: function sendCoachingFeedback(comment, closeKebabMenu) {
        this.get('emailCoachingFeedback')(comment);
        closeKebabMenu();
      }
    }
  });

  _exports.default = _default;
});