define("organization-settings/adapters/setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    namespace: 'api',
    buildURL: function buildURL(modelName, id, snapshot) {
      var url = this._super.apply(this, arguments); // If key exist, just replace with the key


      if (id) {
        // TODO: Better way then using private keys?
        url = url.replace(id, snapshot._attributes.key);
      }

      return url;
    }
  });

  _exports.default = _default;
});