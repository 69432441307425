define("workcompass-components/components/latest-ratings", ["exports", "workcompass-components/components/other-party-rating", "workcompass-components/templates/components/latest-ratings"], function (_exports, _otherPartyRating, _latestRatings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _otherPartyRating.default.extend({
    layout: _latestRatings.default,
    usesPercentageGoalRatings: false,
    staffMemberRatingLabel: Ember.computed('ratingOptions.@each.label', 'model.staffMemberRating', function () {
      return this.getLabel(this.get('model.staffMemberRating.score'));
    }),
    textForStaffMemberRating: Ember.computed('model.staffMemberRating', 'usesPercentageGoalRatings', function () {
      return this.getText(this.get('model.staffMemberRating'), this.get('staffMemberRatingLabel'));
    }),
    latestReviewerRatingLabel: Ember.computed('ratingOptions.@each.label', 'model.latestReviewerRating', function () {
      return this.getLabel(this.get('model.latestReviewerRating.score'));
    }),
    textForLatestReviewerRating: Ember.computed('model.latestReviewerRating', 'usesPercentageGoalRatings', function () {
      return this.getText(this.get('model.latestReviewerRating'), this.get('latestReviewerRatingLabel'));
    })
  });

  _exports.default = _default;
});