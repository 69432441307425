define("design-system/templates/components/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Su1ZsN3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Pagination \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Pagination splits content into pages \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass pagination is used to navigate to different years on the calendar page. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" Example 1 shows a simple example of pagination in WorkCompass.\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <a class='workcompass-calendar__prev' {{action 'onClickPrevYear'}} href='#'><img src='/assets/images/arrow.svg'></a>\\n    <a class='workcompass-calendar__next' {{action 'onClickNextYear'}} href='#'><img src='/assets/images/arrow.svg'></a>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"a\"],[11,\"class\",\"workcompass-calendar__prev\"],[11,\"href\",\"#\"],[9],[7,\"img\"],[11,\"src\",\"/assets/images/arrow.svg\"],[9],[10],[3,\"action\",[[22,0,[]],\"onClickPrevYear\"]],[10],[0,\"\\n\"],[7,\"a\"],[11,\"class\",\"workcompass-calendar__next\"],[11,\"href\",\"#\"],[9],[7,\"img\"],[11,\"src\",\"/assets/images/arrow.svg\"],[9],[10],[3,\"action\",[[22,0,[]],\"onClickNextYear\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/pagination.hbs"
    }
  });

  _exports.default = _default;
});