define("organization-settings/models/section-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    publishedName: _emberData.default.attr('string'),
    draftName: _emberData.default.attr('string'),
    draftOrdinal: _emberData.default.attr('number'),
    publishedOrdinal: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    publishedWeight: _emberData.default.attr('number'),
    draftWeight: _emberData.default.attr('number'),
    publishedAt: _emberData.default.attr('date'),
    markedForArchivalAt: _emberData.default.attr('date'),
    archivedAt: _emberData.default.attr('date'),
    draftContributesToPotential: _emberData.default.attr('boolean'),
    publishedContributesToPotential: _emberData.default.attr('boolean'),
    planTemplate: _emberData.default.belongsTo('plan-template', {
      inverse: 'sectionTemplates'
    }),
    ratingOptionTemplates: _emberData.default.hasMany('rating-option-templates'),
    itemTemplates: _emberData.default.hasMany('item-template'),
    itemTemplatesOrdering: ['draftOrdinal:asc'],
    orderedItemTemplates: Ember.computed.sort('itemTemplates', 'itemTemplatesOrdering'),

    /**
     * Computed property that returns the filtered list of item templates.
     *
     * @return {Array} A filtered array of item templates that
     * are not marked for archival.
     */
    displayableItemTemplates: Ember.computed('orderedItemTemplates.@each.{isMarkedForArchival,draftText}', function () {
      return this.orderedItemTemplates.filterBy('isMarkedForArchival', false);
    }),
    ratingOptionsOrdering: ['draftScore'],
    orderedRatingOptionTemplates: Ember.computed.sort('ratingOptionTemplates', 'ratingOptionsOrdering'),

    /**
     * Computed property that returns the filtered list of rating
     * option templates.
     *
     * @return {Array} A filtered array of rating option templates that
     * are not marked for archival.
     */
    displayableRatingOptionTemplates: Ember.computed('orderedRatingOptionTemplates.@each.{isMarkedForArchival,draftLabel}', function () {
      return this.orderedRatingOptionTemplates.filterBy('isMarkedForArchival', false);
    }),
    isQuestion: Ember.computed.equal('type', 'Question'),
    isGoal: Ember.computed.equal('type', 'Goal'),
    isMultipleChoice: Ember.computed.equal('type', 'MultipleChoice'),
    isRateable: Ember.computed.or('isGoal', 'isMultipleChoice'),
    isDraft: Ember.computed('publishedAt', function () {
      return Boolean(this.get('publishedAt')) === false;
    }),
    isPublished: Ember.computed('publishedAt', 'isArchived', function () {
      return this.get('publishedAt') && !this.get('isArchived');
    }),
    isArchived: Ember.computed('archivedAt', function () {
      return Boolean(this.get('archivedAt'));
    }),
    isMarkedForArchival: Ember.computed('markedForArchivalAt', function () {
      return Boolean(this.get('markedForArchivalAt'));
    }),
    shouldShowAddNewQuestion: Ember.computed.and('isQuestion', 'id'),
    shouldHideAddNewQuestion: Ember.computed.not('shouldShowAddNewQuestion'),
    hasRatings: Ember.computed.notEmpty('displayableRatingOptionTemplates'),
    doesNotHaveRatings: Ember.computed.not('hasRatings'),
    shouldShowAddRatings: Ember.computed.and('isMultipleChoice', 'doesNotHaveRatings'),
    shouldHideAddRatings: Ember.computed('hasRatings', function () {
      return Boolean(this.get('hasRatings') || !this.get('isRateable'));
    }),
    shouldShowRemoveRatings: Ember.computed.and('isMultipleChoice', 'hasRatings'),
    shouldHideRemoveRatings: Ember.computed.not('shouldShowRemoveRatings'),
    notDraftContributesToPotential: Ember.computed.not('draftContributesToPotential'),
    shouldShowAddContributesToPotential: Ember.computed.and('isMultipleChoice', 'notDraftContributesToPotential'),
    shouldHideAddContributesToPotential: Ember.computed.not('shouldShowAddContributesToPotential'),
    shouldShowRemoveContributesToPotential: Ember.computed.and('isMultipleChoice', 'draftContributesToPotential'),
    shouldHideRemoveContributesToPotential: Ember.computed.not('shouldShowRemoveContributesToPotential'),
    revert: function revert() {
      if (!this.get('isArchived')) {
        this.set('draftName', this.get('publishedName'));
        this.set('draftWeight', this.get('publishedWeight'));
        this.set('markedForArchivalAt', null);
        this.set('draftContributesToPotential', this.get('publishedContributesToPotential'));
      }

      return this;
    }
  });

  _exports.default = _default;
});