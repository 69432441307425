define("workcompass-components/components/card-dotted", ["exports", "workcompass-components/components/trigger-dropdown", "workcompass-components/templates/components/card-dotted"], function (_exports, _triggerDropdown, _cardDotted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _triggerDropdown.default.extend({
    // Component structure
    classNames: ['card-dotted'],
    tagName: 'section',
    layout: _cardDotted.default,
    // Computed
    menuItems: Ember.computed('params', function () {
      var allAttrs = this.get('attrs');
      return Object.keys(allAttrs).filter(function (key) {
        return key.startsWith('menu-');
      }).map(function (key) {
        return allAttrs[key];
      });
    }),
    // Native events
    click: function click(evt) {
      if (evt.target === this.element) {
        // Kebab menu is 1.5 time the fontsize
        // so position would be the fontsize
        // minus times 1.5 minus the offset
        var fontSize = parseFloat(window.getComputedStyle(this.element, null).getPropertyValue('font-size'));
        var kebabMenuPosition = evt.target.offsetWidth - fontSize * 1.5;

        if (evt.clientX > kebabMenuPosition) {
          evt.stopPropagation();
          this.toogle();
        }
      }
    },
    // Actions
    actions: {
      onMenuItemClick: function onMenuItemClick(itemName) {
        var callbackName = "on".concat(Ember.String.classify(itemName), "Clicked");

        if (typeof this[callbackName] === 'function') {
          this[callbackName]();
        }
      }
    }
  });

  _exports.default = _default;
});