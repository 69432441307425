define("plans/models/plan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    staffMember: _emberData.default.belongsTo('staff-member', {
      inverse: 'plans'
    }),
    reviewer: _emberData.default.belongsTo('staff-member'),
    performanceReviewCycle: _emberData.default.belongsTo('performance-review-cycle'),
    sections: _emberData.default.hasMany('section'),
    tasks: _emberData.default.hasMany('task'),
    priorityTask: _emberData.default.belongsTo('priority-task'),
    attachments: _emberData.default.hasMany('attachment'),
    ratingOptions: _emberData.default.hasMany('rating-option'),
    comments: _emberData.default.hasMany('comment'),
    changes: _emberData.default.hasMany('change'),
    ratings: _emberData.default.hasMany('rating'),
    activityLogItems: _emberData.default.hasMany('activity-log-item'),
    reviewRole: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    rating: _emberData.default.attr('number'),
    archivable: _emberData.default.attr('boolean'),
    cancelableCheckIn: _emberData.default.attr('boolean'),
    deletable: _emberData.default.attr('boolean'),
    reopenable: _emberData.default.attr('boolean'),
    updatedAt: _emberData.default.attr('date'),
    otherPartyRating: _emberData.default.attr('number'),
    otherParty: _emberData.default.belongsTo('staff-member'),
    shouldShowOtherPartyRating: _emberData.default.attr('boolean'),
    revieweeCanRate: _emberData.default.attr('boolean'),
    isWeightAssignedByUser: _emberData.default.attr('boolean'),
    rateable: _emberData.default.attr('boolean'),
    staffMemberRating: _emberData.default.belongsTo('rating'),
    latestReviewerRating: _emberData.default.belongsTo('rating'),
    latestReviewer: _emberData.default.belongsTo('staff-member'),
    goalSectionWeight: _emberData.default.attr('number'),
    competencySectionWeight: _emberData.default.attr('number'),
    rawScore: _emberData.default.attr('number'),
    rawEmployeeScore: _emberData.default.attr('number'),
    dueYear: _emberData.default.attr('number'),
    nextPlanId: _emberData.default.attr('number'),
    prevPlanId: _emberData.default.attr('number'),
    firstPlanId: _emberData.default.attr('number'),
    lastPlanId: _emberData.default.attr('number'),
    isReviewee: Ember.computed.equal('reviewRole', 'reviewee'),
    isReviewer: Ember.computed.equal('reviewRole', 'reviewer'),
    isRevieweeOrReviewer: Ember.computed.or('isReviewee', 'isReviewer'),
    isArchived: Ember.computed.equal('status', 'archived'),
    commentsOrdering: ['createdAt:desc'],
    orderedComments: Ember.computed.sort('comments', 'commentsOrdering'),
    ratingsOrdering: ['createdAt:desc'],
    orderedRatings: Ember.computed.sort('ratings', 'ratingsOrdering'),
    commentsAndRatings: Ember.computed('orderedComments', 'orderedRatings', function () {
      return this.get('orderedComments').concat(this.get('orderedRatings'));
    }),
    orderedCommentsAndRatings: Ember.computed.sort('commentsAndRatings', 'ratingsOrdering'),
    sectionsOrdering: ['ordinal'],
    orderedSections: Ember.computed.sort('sections', 'sectionsOrdering'),
    ratingOptionsOrdering: ['score:asc'],
    orderedRatingOptions: Ember.computed.sort('ratingOptions', 'ratingOptionsOrdering'),
    activityLogItemsOrdering: ['occurredAt:desc'],
    orderedActivityLogItems: Ember.computed.sort('activityLogItems', 'activityLogItemsOrdering'),
    notYetRated: Ember.computed.empty('latestReviewerRating.score'),
    review: function review(params) {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.review(this, params);
    }
  });

  _exports.default = _default;
});