define("plans/templates/components/printable-comment-or-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y/BwbUOL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"rating-box row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[\"comment-list__comment-type-icon \",[21,\"commentTypeClassName\"]]]],[9],[0,\" \"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"other-party-rating__name\"],[9],[0,\" \"],[1,[21,\"label\"],false],[0,\" \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"rating-box row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"other-party-rating__name\"],[9],[0,\" \"],[1,[27,\"comment-change-date\",[[23,[\"item\",\"createdAt\"]]],null],false],[0,\" \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"side-picture-wrapper\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"comment-list__commenter-picture\"],[12,\"src\",[23,[\"item\",\"user\",\"pictureURL\"]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"other-party-rating__wrapper\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"other-party-rating__name\"],[9],[0,\" \"],[1,[23,[\"item\",\"user\",\"name\"]],false],[0,\" \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"other-party-rating__name\"],[9],[0,\" \"],[1,[23,[\"item\",\"textToDisplay\"]],false],[0,\" \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/components/printable-comment-or-change.hbs"
    }
  });

  _exports.default = _default;
});