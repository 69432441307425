define("organization-settings/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y3Exwaox",
    "block": "{\"symbols\":[\"category\",\"item\"],\"statements\":[[7,\"nav\"],[11,\"class\",\"side-navbar\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"menu\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"children\"]]],null,{\"statements\":[[0,\"      \"],[7,\"details\"],[11,\"class\",\"main-menu\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"changeState\"],null]],[12,\"open\",[22,1,[\"open\"]]],[9],[0,\"\\n        \"],[7,\"summary\"],[9],[1,[22,1,[\"display\"]],false],[10],[0,\"\\n        \"],[7,\"section\"],[11,\"class\",\"sub-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"children\"]]],null,{\"statements\":[[4,\"if\",[[22,2,[\"id\"]]],null,{\"statements\":[[4,\"link-to\",[[22,2,[\"linkTo\"]],[22,2,[\"id\"]]],[[\"class\"],[[22,2,[\"class\"]]]],{\"statements\":[[0,\"                \"],[1,[22,2,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[22,2,[\"linkTo\"]]],[[\"class\"],[[22,2,[\"class\"]]]],{\"statements\":[[0,\"                \"],[1,[22,2,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[22,1,[\"linkTo\"]]],[[\"class\"],[\"nav-link main-menu main-item\"]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"panel\"],[9],[0,\"\\n  \"],[7,\"header\"],[11,\"class\",\"panel__header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"panel__title\"],[9],[1,[23,[\"page\",\"display\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"panel__description\"],[9],[1,[23,[\"page\",\"description\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"panel_content\"],[9],[1,[21,\"outlet\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/application.hbs"
    }
  });

  _exports.default = _default;
});