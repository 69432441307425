define("workcompass-components/components/wc-copy-icon", ["exports", "workcompass-components/templates/components/wc-copy-icon"], function (_exports, _wcCopyIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcCopyIcon.default,
    actions: {
      copyText: function copyText(index) {
        var copyText = document.getElementsByClassName('copy-text-input')[index];
        copyText.select();
        document.execCommand('copy');
      }
    }
  });

  _exports.default = _default;
});