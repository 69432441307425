define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AujWseNU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"fluid\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\"],[[27,\"component\",[\"bs-navbar/toggle\"],[[\"onClick\",\"collapsed\"],[[27,\"action\",[[22,0,[]],\"toggleNavbar\"],null],[23,[\"_collapsed\"]]]]],[27,\"component\",[\"bs-navbar/content\"],[[\"collapsed\",\"onHidden\",\"onShown\"],[[23,[\"_collapsed\"]],[23,[\"onCollapsed\"]],[23,[\"onExpanded\"]]]]],[27,\"component\",[\"bs-navbar/nav\"],[[\"linkToComponent\"],[[27,\"component\",[\"bs-navbar/link-to\"],[[\"onCollapse\"],[[27,\"action\",[[22,0,[]],\"collapse\"],null]]]]]]],[27,\"action\",[[22,0,[]],\"collapse\"],null],[27,\"action\",[[22,0,[]],\"expand\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[14,1,[[27,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\"],[[27,\"component\",[\"bs-navbar/toggle\"],[[\"onClick\",\"collapsed\"],[[27,\"action\",[[22,0,[]],\"toggleNavbar\"],null],[23,[\"_collapsed\"]]]]],[27,\"component\",[\"bs-navbar/content\"],[[\"collapsed\",\"onHidden\",\"onShown\"],[[23,[\"_collapsed\"]],[23,[\"onCollapsed\"]],[23,[\"onExpanded\"]]]]],[27,\"component\",[\"bs-navbar/nav\"],[[\"linkToComponent\"],[[27,\"component\",[\"bs-navbar/link-to\"],[[\"onCollapse\"],[[27,\"action\",[[22,0,[]],\"collapse\"],null]]]]]]],[27,\"action\",[[22,0,[]],\"collapse\"],null],[27,\"action\",[[22,0,[]],\"expand\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});