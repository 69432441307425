define("plans/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('team-bar', {
      path: ''
    }, function () {
      this.route('plan', {
        path: '/:id'
      });
      this.route('staff-member', {
        path: '/staff-member/:id'
      });
    });
    this.route('print', {
      path: '/:id/print'
    });
  });

  _exports.default = _default;
});