define("plans/components/printable-goal", ["exports", "plans/templates/components/printable-goal", "plans/mixins/section-items"], function (_exports, _printableGoal, _sectionItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionItems.default, {
    layout: _printableGoal.default,
    classNames: ['printable-plan-item', 'printable-goal'],
    items: Ember.computed.alias('goal.orderedMilestones'),
    milestoneCommentArrays: Ember.computed.alias('goal.milestones.@each.comments'),
    milestoneProgressUpdateArrays: Ember.computed.alias('goal.milestones.@each.progressUpdates'),
    milestoneChangeArrays: Ember.computed.alias('goal.milestones.@each.changes'),
    allComments: Ember.computed('goal.{comments.[],ratings.[]}', 'milestoneCommentArrays.[]', 'milestoneProgressUpdateArrays.[]', 'milestoneChangeArrays.[]', function () {
      var cs = this.get('goal.comments').filter(function (c) {
        return c.get('private') === false;
      }).toArray();
      this.get('goal.milestones').forEach(function (m) {
        return cs = cs.concat(m.get('comments').toArray(), m.get('progressUpdates').toArray());
      });
      cs = cs.concat(this.get('goal.ratings').toArray());
      return cs.sortBy('createdAt').reverse();
    })
  });

  _exports.default = _default;
});