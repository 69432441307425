define("workcompass-components/components/plan-header", ["exports", "workcompass-components/templates/components/plan-header"], function (_exports, _planHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _planHeader.default,
    showKebabMenu: true,
    showDeletePlanModal: false,
    showActivityLog: false,
    options: Ember.computed('plan.deletable', function () {
      var _this = this;

      if (!this.plan) return [];
      var options = [{
        label: 'Print',
        id: 'printing',
        class: 'kebab-menu__content-item-print',
        action: function action() {
          return _this.send('openPrintPage');
        }
      }];

      if (this.plan.deletable) {
        options.push({
          label: 'Delete',
          id: 'deleting',
          class: 'kebab-menu__content-item-delete',
          action: function action(dropdown) {
            return _this.send('confirmDeletePlan', dropdown);
          }
        });
      }

      return options;
    }),
    disablePaginationPrevious: Ember.computed('plan.{id,firstPlanId}', function () {
      if (this.plan) {
        return this.plan.id == this.plan.firstPlanId;
      }
    }),
    disablePaginationNext: Ember.computed('plan.{id,lastPlanId}', function () {
      if (this.plan) {
        return this.plan.id == this.plan.lastPlanId;
      }
    }),
    showDefaultProfilePicture: false,
    profilePictureURL: Ember.computed('staffMember.pictureURL', 'showDefaultProfilePicture', function () {
      if (this.get('showDefaultProfilePicture')) {
        return '/avatar.png';
      } else {
        return this.get('staffMember.pictureURL');
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Options for Ctr + P


      Ember.$(document).bind('keyup keydown', function (e) {
        if (e.ctrlKey && e.keyCode == 80) {
          if (navigator.appVersion.indexOf('Edge') > -1) {
            window.open("/plans/".concat(document.location.href.match(/[0-9]+/)[0], "/print"));
            /* IE */
          } else {
            document.location.href = "/plans/".concat(document.location.href.match(/[0-9]+/)[0], "/print");
          }

          e.preventDefault();
        }
      }); // Options for window.onbeforeprint

      window.onbeforeprint = function () {
        document.location.href = "/plans/".concat(document.location.href.match(/[0-9]+/)[0], "/print");

        if (navigator.userAgent.indexOf('Firefox') != -1) {
          alert('Redirecting to the PRINT page');
        }
      };
    },
    actions: {
      confirmDeletePlan: function confirmDeletePlan(dropdown) {
        this.set('showDeletePlanModal', true);
        dropdown.actions.close();
      },
      deletePlanButtonClicked: function deletePlanButtonClicked() {
        this.get('deletePlan')();
      },
      openPrintPage: function openPrintPage() {
        var planId = this.get('planId');
        document.location.href = "/plans/".concat(planId, "/print");
      },
      showActivityLog: function showActivityLog() {
        this.set('showActivityLog', true);
      },
      hideActivityLog: function hideActivityLog() {
        this.set('showActivityLog', false);
      }
    }
  });

  _exports.default = _default;
});