define("organization-settings/controllers/application", ["exports", "organization-settings/helpers/can-manage", "organization-settings/constants/nav-items", "organization-settings/config/environment"], function (_exports, _canManage, _navItems, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var CSS_NAV_CLS = 'nav-link';
  var CSS_NAV_SELECTED_CLS = "".concat(CSS_NAV_CLS, "--selected");

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.currentUser'),

    /**
     * Computed properties to get the navigation items.
     */
    menu: Ember.computed('currentUser', 'target.currentRouteName', function () {
      var _this = this;

      var navigation = _navItems.NAV_ITEMS.reduce(function (list, item) {
        if (!(0, _canManage.canManage)([item.linkTo, _this.currentUser])) return list;
        var selected = _this.path === item.linkTo;
        var classes = [CSS_NAV_CLS, selected ? CSS_NAV_SELECTED_CLS : ''];

        var menu_item = _objectSpread(_objectSpread({}, item), {}, {
          class: classes.join(' '),
          selected: selected
        });

        return _this.categoriesFor(menu_item, list);
      }, []);

      navigation.forEach(function (n) {
        if (n.children) {
          n.open = n.children.some(function (i) {
            return i.selected;
          });
        }
      });
      return navigation;
    }),

    /**
     * Retrieve categories for a specific item and build/update
     * the list of children.
     *
     * @param {{
     *  categories: String | Array<String>,
     *  linkTo: String,
     * }} item
     * @param {Array({
     *   display: String,
     *   children?: Array<*>,
     * })} list
     * @returns
     */
    categoriesFor: function categoriesFor(item, list) {
      if (!item.categories) {
        list.push(item);
        return list;
      }

      var categories = Array.isArray(item.categories) ? item.categories : [item.categories];
      categories.forEach(function (category) {
        var index = list.findIndex(function (obj) {
          return obj.display === category;
        });

        if (index > -1) {
          list[index].children.push(item);
        } else {
          list.push({
            display: category,
            children: [item]
          });
        }
      });
      return list;
    },

    /**
     * Get the current organization settings path without
     * namespace.
     */
    path: Ember.computed('target.currentRouteName', function () {
      var path = this.target.currentRouteName;
      return path.replace("".concat(_environment.default.modulePrefix, "."), '');
    }),

    /**
     * Get the page information details
     */
    page: Ember.computed('target.currentRouteName', function () {
      var _this2 = this;

      return _navItems.NAV_ITEMS.find(function (item) {
        return item.linkTo == _this2.path;
      });
    }),
    actions: {
      /**
       * Handle menu animation and state when menu item is clicked.
       *
       * @param {HTMLEvent} evt
       */
      changeState: function changeState(evt) {
        var targetCat = evt.target.closest('.main-menu') || evt.target;
        var summary = targetCat.querySelector('summary');
        var navbar = evt.target.closest('.side-navbar');
        navbar.querySelectorAll('.main-menu[open]').forEach(function (el) {
          var current = el.querySelector(".".concat(CSS_NAV_SELECTED_CLS));

          if (el != targetCat && !current) {
            el.removeAttribute('open');
          }
        });

        if (summary == evt.target) {
          evt.preventDefault(); // Change category state a few second after to let the CSS
          // animation finalise and smoother.

          Ember.run.later(targetCat, function () {
            this.setAttribute('open', 'open');
          }, 500);
        }
      }
    }
  });

  _exports.default = _default;
});