define("workcompass-components/components/overall-performance-card", ["exports", "workcompass-components/components/rateable-card", "workcompass-components/templates/components/overall-performance-card"], function (_exports, _rateableCard, _overallPerformanceCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rateableCard.default.extend({
    layout: _overallPerformanceCard.default,
    store: Ember.inject.service(),
    anyPlanComments: Ember.computed.notEmpty('plan.comments'),
    anyPlanRatings: Ember.computed.notEmpty('plan.ratings'),
    anyPlanCommentsOrRatings: Ember.computed.or('anyPlanComments', 'anyPlanRatings'),
    // Retrieve mascot for suggested rating
    mascot: Ember.computed('', function () {
      var mascot = this.store.peekRecord('setting', 'chat-mascot');

      if (mascot) {
        return mascot.value;
      }

      return '/assets/images/bot-avatar-small.png';
    }),

    /**
     * Flag to indicate if displaying
     * manager decimal rating
     */
    showDecimalRating: Ember.computed('plan.isReviewer', 'shouldShowOtherPartyRating', function () {
      return this.plan.isReviewer || this.shouldShowOtherPartyRating;
    }),
    suggestedRatingText: Ember.computed('suggestedRating', function () {
      var sugRating = this.suggestedRating;
      var ratingOption = this.get('plan.ratingOptions').findBy('score', sugRating);

      if (ratingOption) {
        return ratingOption.get('label');
      }
    }),

    /**
     * Get the manager's average score text.
     * @param {Float|null} managerAverageScore - The manager's average score.
     * @return {String} The manager's average score as a string,
     * or 'Not yet rated' if managerAverageScore is null.
     */
    managerAverageScoreText: Ember.computed('managerAverageScore', function () {
      return this.managerAverageScore ? this.managerAverageScore.toString() : 'Not yet rated';
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.selectRating(this.get('plan.rating'));
    },
    change: function change(event) {
      var plan = this.get('plan');
      this.changeEvent(event, plan);
    }
  });

  _exports.default = _default;
});