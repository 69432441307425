define("chat/components/chat-box", ["exports", "chat/templates/components/chat-box"], function (_exports, _chatBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _chatBox.default,
    chatInputValue: '',
    store: Ember.inject.service(),
    responseParts: [],
    chatMascot: '/assets/images/blinky-chat-bot.gif',
    waitingForInput: false,
    showInputButton: false,
    endingChat: true,
    userInputs: [],
    randEnquireIds: [],
    randQ: {},
    canShowButton: Ember.observer('chatInputValue', function () {
      var textInput = this.get('chatInputValue').replace(/\s/g, '');

      if (textInput.length == 0) {
        this.set('showInputButton', false);
      } else {
        this.set('showInputButton', true);
      }
    }),
    getResponseParts: function getResponseParts(toResponsePartId) {
      var _this = this;

      this.get('store').findRecord('response-part', toResponsePartId).then(function (responsePart) {
        _this.get('responseParts').pushObject(responsePart);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments); // Initializer


      this.set('responseParts', []);
      this.set('userInputs', []);
      this.set('randEnquireIds', []);
      this.set('randQ', {}); // Load data

      this.getResponseParts(this.get('startingResponsePartUUID')); // Load and randomize EnquiryQuestion

      this.get('store').findAll('enquiry-question').then(function (enquires) {
        var ids = [];
        enquires.forEach(function (enq) {
          ids.push(parseInt(enq.get('id')));
        });
        ids.sort(function () {
          return Math.random() - 0.5;
        });

        _this2.set('randEnquireIds', ids);

        var rq = {};
        ids.forEach(function (id, index) {
          var text = enquires.filter(function (enq) {
            return enq.get('id') == id;
          })[0].get('question');
          rq[index + 1] = text;
        });

        _this2.set('randQ', rq);
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.$('.chat-box__body').scrollTop(1000000000);
    },
    actions: {
      onOptionSelected: function onOptionSelected(option) {
        var toResponsePartId = option.get('toResponsePartId');
        this.getResponseParts(toResponsePartId);
      },
      showInput: function showInput(key) {
        var _this3 = this;

        var oldInput = this.get('userInputs').findBy('key', key);
        this.get('userInputs').removeObject(oldInput);
        this.set('userInputKey', key);
        this.set('waitingForInput', true);
        Ember.run.later(function () {
          _this3.$('.chat-box__input-textarea').focus();
        });
      },
      keyPressed: function keyPressed() {
        var enterKeyCode = 13;

        if (this.get('showInputButton') && event.keyCode == enterKeyCode) {
          // eslint-disable-line no-restricted-globals, no-undef
          event.preventDefault(); // eslint-disable-line no-restricted-globals, no-undef

          this.send('saveUserInput');
        }
      },
      saveUserInput: function saveUserInput() {
        var val = this.get('chatInputValue');
        var key = this.get('userInputKey');
        this.get('userInputs').pushObject({
          key: key,
          value: val
        });
        this.set('waitingForInput', false);
        this.set('showInputButton', false);
        this.set('userInputKey', null);
        this.set('chatInputValue', '');
      },
      closeCoachingAssistant: function closeCoachingAssistant() {
        this.get('hideCoachingAssistant')();
      }
    }
  });

  _exports.default = _default;
});