define("organization-settings/controllers/organization-goals", ["exports", "organization-settings/controllers/editable-list"], function (_exports, _editableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _editableList.default.extend({
    propName: 'description'
  });

  _exports.default = _default;
});