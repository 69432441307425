define("design-system/templates/components/copy-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFTh4gdR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Copy Icon Component \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" The copy icon component can be used to copy the text of an input. \"],[10],[0,\"\\n\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows an example of a simple copy icon component.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    {{wc-copy-icon text='abcde12345'}}\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"wc-copy-icon\",null,[[\"text\"],[\"abcde12345\"]]],false],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/copy-icon.hbs"
    }
  });

  _exports.default = _default;
});