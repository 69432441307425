define("ember-bootstrap/templates/components/bs-accordion/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCE+nvLg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"bs-collapse\",null,[[\"ariaRole\",\"collapsed\"],[\"tabpanel\",[23,[\"collapsed\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"card-body \",[21,\"class\"]]]],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/body.hbs"
    }
  });

  _exports.default = _default;
});