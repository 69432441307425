define("organization-settings/models/phase", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    startOn: _emberData.default.attr('date'),
    endOn: _emberData.default.attr('date'),
    startMonth: Ember.computed('startOn', function () {
      return this.get('startOn').getMonth();
    }),
    startDate: Ember.computed('startOn', function () {
      return this.get('startOn').getDate();
    }),
    endDate: Ember.computed('endOn', function () {
      return this.get('endOn').getDate();
    }),
    endMonth: Ember.computed('endOn', function () {
      return this.get('endOn').getMonth();
    }),
    startYear: Ember.computed('startOn', function () {
      return this.get('startOn').getFullYear();
    }),
    endYear: Ember.computed('endOn', function () {
      return this.get('endOn').getFullYear();
    }),
    startPosition: Ember.computed('startDate', function () {
      return this.get('startDate') * 3.1;
    }),
    startPositionLimit: Ember.computed('startDate', function () {
      return 0;
    }),
    topPosition: Ember.computed(function () {
      return 45;
    }),
    widthStart: Ember.computed(function () {
      return 100 - this.get('startPosition');
    }),
    width: Ember.computed('startMonth', 'endMonth', 'startDate', 'endDate', function () {
      if (this.get('startMonth') - this.get('endMonth') == 0) {
        return (this.get('endDate') - this.get('startDate')) * 3.2;
      } else {
        return this.get('endDate') * 3.18;
      }
    }),
    widthAll: Ember.computed('startMonth', function () {
      return 100;
    }),
    cycle: _emberData.default.belongsTo('performance-review-cycle')
  });

  _exports.default = _default;
});