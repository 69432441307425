define("organization-settings/templates/components/editable-rating-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9lVqnkkT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"col-1 editable-rating-options__rating-option-score\"],[9],[0,\"\\n  \"],[1,[23,[\"ratingOptionTemplate\",\"draftScore\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"col p-0\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"required\",\"name\",\"type\",\"class\",\"value\",\"placeholder\",\"pattern\",\"input\"],[true,[27,\"concat\",[\"ratingLabel-\",[23,[\"index\"]]],null],\"text\",\"editable-rating-options__rating-option-label\",[23,[\"ratingOptionTemplateProxy\",\"draftLabel\"]],\"Type your rating here...\",\".*\\\\S+.*\",[27,\"action\",[[22,0,[]],\"handleInputChange\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"col-1\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"canArchive\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"editable-rating-options__rating-option-archive-button\"],[9],[7,\"img\"],[11,\"src\",\"/assets/images/close-button.png\"],[9],[10],[3,\"action\",[[22,0,[]],\"removeRatingOptionTemplate\",[23,[\"ratingOptionTemplateProxy\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/components/editable-rating-option.hbs"
    }
  });

  _exports.default = _default;
});