define("organization-settings/controllers/admin/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    // Flag if send invite
    // dialog is display
    sendInviteDialogOpened: false,
    // Flag to show
    notice: null,
    actions: {
      /**
       * Update state of the dialog to open
       */
      openConfirmation: function openConfirmation() {
        this.set('sendInviteDialogOpened', true);
      },

      /**
       * Update state of dialog to close
       */
      onDialogClose: function onDialogClose() {
        this.set('sendInviteDialogOpened', false);
      },
      sendInvitations: function sendInvitations() {
        var _this = this;

        this.set('notice', null);
        this.ajax.request('/api/users/reinvite', {
          method: 'POST'
        }).then(function () {
          _this.set('notice', {
            style: 'success',
            message: 'Invitation will send as soon as possible.'
          });
        }).catch(function (error) {
          _this.set('notice', {
            style: 'error',
            message: 'Unable to send invitations.'
          });

          _this.rollbar.error('Unable to reinvite users', error);
        });
      }
    }
  });

  _exports.default = _default;
});