define("plans/components/printable-development-need", ["exports", "plans/templates/components/printable-development-need"], function (_exports, _printableDevelopmentNeed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _printableDevelopmentNeed.default,
    classNames: ['printable-plan-item', 'printable-development-need']
  });

  _exports.default = _default;
});