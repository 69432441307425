define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YtXBSPsA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[23,[\"labelComponent\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[1,[27,\"component\",[[23,[\"errorsComponent\"]]],null],false],[0,\"\\n\"],[1,[27,\"component\",[[23,[\"helpTextComponent\"]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs"
    }
  });

  _exports.default = _default;
});