define("design-system/templates/components/color-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kDJFoqHw",
    "block": "{\"symbols\":[\"colorName\",\"index\"],\"statements\":[[7,\"h1\"],[9],[0,\" Color palette \"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"colorNames\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"wc-color-palette-card \",[22,1,[\"colorName\"]]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"wc-color-palette-card-container\"],[9],[0,\"\\n          \"],[7,\"div\"],[9],[0,\" $\"],[1,[22,1,[\"colorName\"]],false],[10],[0,\"\\n          \"],[7,\"div\"],[9],[0,\" \"],[1,[22,1,[\"colorHex\"]],false],[10],[0,\"\\n          \"],[1,[27,\"wc-copy-icon\",null,[[\"text\",\"index\"],[[22,1,[\"colorName\"]],[22,2,[]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/color-palette.hbs"
    }
  });

  _exports.default = _default;
});