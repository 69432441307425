define("plans/mixins/section-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Boolean property indicating whether to filter only approved items.
     * This property can be overwritten by subclasses.
     * Default value is true.
     */
    onlyApproved: true,

    /**
     * Method indicating the criteria to filter items.
     * Default value is a function that filters only approved items.
     * Items marked as both 'removed?' and 'approved?' are excluded,
     * as they are not sent by the backend API.
     * [https://github.com/WorkCompass/workcompass/blob/ \
     * master/api/app/commands/smart/save_plan.rb#L62]
     * 
     * @param {Object} item - The item to be checked against the filter criteria.
     * @return {Boolean}
     */
    onFilter: function onFilter(item) {
      var approvedItem = 'approved?' in item ? item['approved?'] : true;
      return this.onlyApproved ? approvedItem : !item['removed?'];
    },

    /**
     * Filters items based on criteria defined in the callback method 'onFilter.'
     * 
     * @param {Boolean} onlyApproved
     * @return {Array<*>} - The list of filtered items.
     */
    filteredItems: Ember.computed('itemsToFilter.@each', function () {
      var _this = this;

      return this.items.filter(function (item) {
        return _this.onFilter(item);
      });
    })
  });

  _exports.default = _default;
});