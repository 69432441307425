define("organization-settings/templates/components/plan-template-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yThECgQF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"plan-header plan-header__template\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"plan-header__cycle-title\"],[9],[0,\" \"],[1,[21,\"planYear\"],false],[0,\" \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"plan-header__separator\"],[9],[10],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"plan-header__staff-member-picture\"],[11,\"src\",\"/assets/images/avatar.png\"],[9],[10],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"plan-header__staff-member-name\"],[9],[0,\" Walter Magdaleno \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/components/plan-template-header.hbs"
    }
  });

  _exports.default = _default;
});