define("workcompass-components/components/milestone-row", ["exports", "workcompass-components/templates/components/milestone-row", "ember-autosave"], function (_exports, _milestoneRow, _emberAutosave) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _milestoneRow.default,
    session: Ember.inject.service('session'),
    displayPrompt: false,
    classNames: ['card__body-row', 'milestone-row', 'row'],
    classNameBindings: ['isDueNow:milestone-row--due-now'],
    isDueNow: Ember.computed('milestone.needsProgressUpdate', function () {
      return this.get('milestone.needsProgressUpdate');
    }),
    anyMilestoneComments: Ember.computed.notEmpty('milestone.comments'),
    lastComment: Ember.computed('anyMilestoneComments', 'milestone.comments.@each.text', function () {
      return this.get('anyMilestoneComments') ? this.get('milestone.orderedComments.firstObject') : null;
    }),
    milestoneProxy: (0, _emberAutosave.default)('milestone', {
      save: function save(model) {
        model.save();
      }
    }),
    actions: {
      updateProgress: function updateProgress(newProgressValue) {
        this.set('milestoneProxy.progressValue', newProgressValue);
        this.set('milestoneProxy.progressUpdatedAt', new Date());

        if (!this.get('shouldShowCoachingAssistant')) {
          this.set('displayPrompt', true);
        }
      },
      progressUpdateCommentCreated: function progressUpdateCommentCreated() {
        this.set('milestoneProxy.progressUpdatedAt', new Date());
      }
    }
  });

  _exports.default = _default;
});