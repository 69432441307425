define("organization-settings/routes/application", ["exports", "organization-settings/routes/abstract-router", "organization-settings/helpers/can-manage"], function (_exports, _abstractRouter, _canManage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractRouter.default.extend({
    /**
     * Executes before the model hook. It checks if the current user has the
     * required permission for a given route. If the user doesn't have the
     * required permission, it transitions to the 'errors.not-authorised' route.
     *
     * @param {Transition} transition - The current transition object.
     */
    beforeModel: function beforeModel(transition) {
      // If the targetName does not start with 'organization-settings', stop processing
      if (!transition.targetName.startsWith('organization-settings')) {
        return;
      }

      if (transition.targetName == 'organization-settings.index') {
        return this.transitionTo('performance-review-calendars', new Date().getFullYear());
      } // If the permission does not exist for the route, return early


      if (!(0, _canManage.hasPermission)(transition.to.name, this.session.currentUser)) return;

      if (!(0, _canManage.canManage)([transition.to.name, this.session.currentUser])) {
        this.transitionTo('errors.not-authorised');
        return;
      }
    }
  });

  _exports.default = _default;
});