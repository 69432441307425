define("plans/templates/components/printable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2NbO3HGX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isGoalItem\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[27,\"printable-goal\",null,[[\"goal\",\"onlyApproved\",\"number\",\"usesPercentageGoalRatings\",\"shouldShowOtherPartyRating\",\"revieweeCanRate\"],[[23,[\"item\"]],[23,[\"onlyApproved\"]],[23,[\"itemNumber\"]],[23,[\"usesPercentageGoalRatings\"]],[23,[\"shouldShowOtherPartyRating\"]],[23,[\"revieweeCanRate\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isMultipleChoiceItem\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[27,\"printable-competency\",null,[[\"competency\",\"number\",\"shouldShowOtherPartyRating\",\"revieweeCanRate\"],[[23,[\"item\"]],[23,[\"itemNumber\"]],[23,[\"shouldShowOtherPartyRating\"]],[23,[\"revieweeCanRate\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isFreeTextItem\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[27,\"printable-development-need\",null,[[\"developmentNeed\",\"number\"],[[23,[\"item\"]],[23,[\"itemNumber\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isQuestionItem\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[27,\"printable-question\",null,[[\"question\",\"number\"],[[23,[\"item\"]],[23,[\"itemNumber\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/components/printable-item.hbs"
    }
  });

  _exports.default = _default;
});