define("organization-settings/controllers/authentication-options/azure-oauth2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    redirectURI: "".concat(window.location.origin, "/auth/azure_oauth2/callback"),
    loginURI: "".concat(window.location.origin, "/auth/azure_oauth2"),
    editingAzureConfig: false,
    notEditingAzureConfig: Ember.computed.not('editingAzureConfig'),
    previousClientSecret: '',
    previousClientId: '',
    previousTenantId: '',
    actions: {
      saveAzureConfig: function saveAzureConfig() {
        this.get('model').save();
        this.set('editingAzureConfig', false);
      },
      editAzureConfig: function editAzureConfig() {
        this.set('editingAzureConfig', true);
        this.set('previousClientSecret', this.get('model.clientSecret'));
        this.set('previousClientId', this.get('model.clientId'));
        this.set('previousTenantId', this.get('model.tenantId'));
        this.set('model.clientSecret', '');
      },
      cancelAzureConfig: function cancelAzureConfig() {
        this.set('editingAzureConfig', false);
        this.set('model.clientSecret', this.get('previousClientSecret'));
        this.set('model.clientId', this.get('previousClientId'));
        this.set('model.tenantId', this.get('previousTenantId'));
        this.set('previousClientSecret', '');
      }
    }
  });

  _exports.default = _default;
});