define("organization-settings/constants/nav-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NAV_ITEMS = void 0;
  var NAV_ITEMS = [{
    linkTo: 'admin/users',
    display: 'Users Administration',
    categories: 'User Management',
    description: 'Manage users in the WorkCompass system.'
  }, {
    linkTo: 'third-parties',
    display: 'External Connectors',
    categories: 'User Management',
    description: 'Effortlessly synchronize your employee data with our curated integrations.'
  }, {
    linkTo: 'users-sync',
    display: 'Synchronization Configuration',
    categories: 'User Management',
    description: 'Configure synchronization settings for external connectors. Define filters and rules to manage which employees are imported or excluded from the synchronization process.'
  }, {
    linkTo: 'adp-setup',
    display: 'ADP Integration Setup',
    categories: 'User Management',
    description: 'Configure integration settings for ADP. Establish connections and synchronize data between your organization\'s systems and ADP for seamless HR management.'
  }, {
    linkTo: 'performance-review-calendars',
    display: 'Annual Review Configuration',
    id: new Date().getFullYear(),
    categories: 'Strategic Planning',
    description: 'Define the phases and timelines for your organization\'s annual performance review process.'
  }, {
    linkTo: 'templates',
    display: 'Plan Templates',
    categories: 'Strategic Planning',
    description: 'Configure every aspect of your organization\'s Performance Plan Template, including sections, ratings, and other configurations used by employees. Design templates tailored to your organization\'s unique needs for effective performance management. Adjustments will only become visible to employees once you publish them. Upon publication, all plans will adopt the new template.'
  }, {
    linkTo: 'organization-goals',
    display: 'Organization Goals',
    categories: 'Strategic Planning',
    description: 'Define the strategic objectives your organization aims to achieve. Departments, divisions, and managers can align their goals with these organizational objectives, fostering alignment and driving collective progress.'
  }, {
    linkTo: 'division-goals',
    display: 'Division Goals',
    categories: ['Strategic Planning', 'Division Management'],
    description: 'Define the strategic objectives each division aims to achieve. Align divisional efforts with these goals to drive progress and contribute to the organization\'s overall success.'
  }, {
    linkTo: 'divisions',
    display: 'Division Administration',
    categories: 'Division Management',
    description: 'Create, update, or delete divisions as needed to organize and streamline your organization\'s hierarchy.'
  }, {
    linkTo: 'engagement-survey',
    display: 'Engagement Survey Template',
    categories: 'Survey Management',
    description: 'Configure template for conducting engagement surveys. Design surveys tailored to gather valuable feedback from employees and drive organizational improvement.'
  }, {
    linkTo: 'password_auth',
    display: 'General Configuration',
    categories: 'Access Management',
    description: 'Configure various authentication settings and general configurations.'
  }, {
    linkTo: 'authentication-options/azure-oauth2',
    display: 'Azure SSO Configuration',
    categories: 'Access Management',
    description: 'Follow step-by-step instructions to configure single sign-on (SSO) settings for Azure. Enter the necessary credentials and settings to ensure seamless and secure user authentication.'
  }, {
    linkTo: 'reports-configuration',
    display: 'Report Configuration',
    description: 'Configure reporting settings to tailor reports according to your organization\'s specific needs. Define parameters and formats to generate insightful reports that drive informed decision-making.'
  }];
  _exports.NAV_ITEMS = NAV_ITEMS;
});