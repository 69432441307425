define("workcompass-components/components/wc-notice", ["exports", "workcompass-components/templates/components/wc-notice"], function (_exports, _wcNotice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcNotice.default,
    tagName: 'aside',
    classNames: ['wc-notice', 'row'],
    classNameBindings: ['styleClass', 'display:wc-notice--visible:wc-notice--hidden'],
    display: true,
    style: 'success',
    styleClass: Ember.computed('style', function () {
      return "wc-notice--".concat(this.get('style'));
    }),
    iconPath: Ember.computed('style', function () {
      return "/assets/images/".concat(this.get('style'), "-icon.png");
    })
  });

  _exports.default = _default;
});