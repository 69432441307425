define("plans/templates/components/printable-comments-and-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WSXUhbZ/",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[23,[\"comments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h5\"],[9],[0,\"Comments and changes\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"comments\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"printable-comment-or-change\",null,[[\"item\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"printable-plan--muted-text\"],[9],[0,\" \"],[1,[21,\"emptyText\"],false],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plans/templates/components/printable-comments-and-changes.hbs"
    }
  });

  _exports.default = _default;
});