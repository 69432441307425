define("organization-settings/models/performance-review-calendar", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cycles: _emberData.default.hasMany('performance-review-cycle'),
    year: Ember.computed('id', function () {
      return parseInt(this.get('id'));
    })
  });

  _exports.default = _default;
});