define("workcompass-components/templates/components/calendar-date-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hxzp7dqR",
    "block": "{\"symbols\":[\"@value\",\"@minDate\",\"@format\"],\"statements\":[[5,\"bootstrap-datepicker-inline\",[],[[\"@value\",\"@startDate\",\"@format\"],[[22,1,[]],[22,2,[]],[22,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/calendar-date-selector.hbs"
    }
  });

  _exports.default = _default;
});