define("workcompass-components/templates/components/form-controls/checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJkouq62",
    "block": "{\"symbols\":[\"box\",\"&default\",\"@name\",\"@label\",\"@onError\"],\"statements\":[[7,\"legend\"],[9],[1,[22,4,[]],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"group-items\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"checkboxes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"form-group-controls__control\"],[9],[0,\"\\n      \"],[7,\"input\"],[12,\"id\",[22,1,[\"id\"]]],[12,\"name\",[22,3,[]]],[12,\"value\",[22,1,[\"value\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n      \"],[7,\"label\"],[12,\"for\",[22,1,[\"id\"]]],[9],[1,[22,1,[\"display\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,5,[]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"error-message\"],[9],[1,[21,\"onError\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/form-controls/checkboxes.hbs"
    }
  });

  _exports.default = _default;
});