define("design-system/controllers/components/modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showModal: false,
    showSecondModal: false,
    actions: {
      launchModalExampleOne: function launchModalExampleOne() {
        this.set('showModal', true);
      },
      closeModalOne: function closeModalOne() {
        this.set('showModal', false);
      },
      launchModalExampleTwo: function launchModalExampleTwo() {
        this.set('showSecondModal', true);
      },
      closeModalTwo: function closeModalTwo() {
        this.set('showSecondModal', false);
      }
    }
  });

  _exports.default = _default;
});