define("wc-calendar/components/cycle-calendar", ["exports", "wc-calendar/templates/components/cycle-calendar"], function (_exports, _cycleCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _cycleCalendar.default,
    attributeBindings: ['dataTest:data-test'],
    classNameBindings: ['typeClassName'],
    classNames: ['cycle-calendar'],
    dataTest: 'cycle-calendar',
    locale: 'en-US',
    type: 'calendar',

    /*
     * A computed property that generates an array of month-year pairs from the 
     * given start date to the end date, inclusive.
     */
    cycleMonths: Ember.computed('startDate', 'endDate', 'locale', function () {
      var startDate = this.startDate,
          endDate = this.endDate,
          locale = this.locale;
      var result = [];
      var currDate = new Date(startDate);

      while (currDate <= new Date(endDate)) {
        result.push({
          name: currDate.toLocaleString(locale, {
            month: 'long',
            year: 'numeric'
          }),
          dataTest: currDate.toLocaleString('en-US', {
            month: 'short',
            year: 'numeric'
          }).toLowerCase().replace(/ /g, '-')
        });
        currDate.setMonth(currDate.getMonth() + 1);
      }

      return result;
    }),

    /**
     * Computed property that maps the type to a specific class name.
     */
    typeClassName: Ember.computed('type', function () {
      var typeClassMap = {
        'timeline': 'cycle-calendar--timeline'
      };
      return typeClassMap[this.type] || '';
    })
  });

  _exports.default = _default;
});