define("workcompass-components/components/competency-card", ["exports", "workcompass-components/components/rateable-card", "workcompass-components/templates/components/competency-card"], function (_exports, _rateableCard, _competencyCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rateableCard.default.extend({
    layout: _competencyCard.default,
    anyCompetencyComments: Ember.computed.notEmpty('competency.comments'),
    anyCompetencyRatings: Ember.computed.notEmpty('competency.ratings'),
    anyCompetencyCommentsOrRatings: Ember.computed.or('anyCompetencyComments', 'anyCompetencyRatings'),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.selectRating(this.get('competency.rating'));
    },
    change: function change(event) {
      var comp = this.get('competency');
      this.changeEvent(event, comp);
    }
  });

  _exports.default = _default;
});