define("design-system/controllers/components/notices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showNotice: false,
    buttonLabel: Ember.computed('showNotice', function () {
      if (this.get('showNotice')) {
        return 'Hide notice';
      } else {
        return 'Show notice';
      }
    }),
    actions: {
      toggleNotice: function toggleNotice() {
        this.set('showNotice', !this.get('showNotice'));
      }
    }
  });

  _exports.default = _default;
});