define("organization-settings/components/editable-rating-option", ["exports", "organization-settings/templates/components/editable-rating-option", "ember-autosave", "organization-settings/components/save-with-notification"], function (_exports, _editableRatingOption, _emberAutosave, _saveWithNotification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _saveWithNotification.default.extend({
    layout: _editableRatingOption.default,
    tagName: 'div',
    classNames: ['row', 'editable-rating-options__rating-option'],
    notifier: Ember.inject.service(),
    canArchive: Ember.computed('ratingOptionTemplate.draftScore', function () {
      var draftScore = this.get('ratingOptionTemplate.draftScore');
      return draftScore !== null && draftScore !== undefined;
    }),
    ratingOptionTemplateProxy: (0, _emberAutosave.default)('ratingOptionTemplate', {
      save: function save(model) {
        this.saveProxy(model);
      }
    }),
    adjustScores: function adjustScores() {},
    actions: {
      removeRatingOptionTemplate: function removeRatingOptionTemplate(ratingOptionTemplateProxy) {
        ratingOptionTemplateProxy.set('markedForArchivalAt', new Date());
        this.adjustScores();
      }
    }
  });

  _exports.default = _default;
});