define("workcompass-components/templates/components/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVGOgp9K",
    "block": "{\"symbols\":[\"@subheader\",\"@header\",\"&default\"],\"statements\":[[4,\"if\",[[27,\"ifNotEmpty\",[[22,2,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"ifNotEmpty\",[[22,1,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"h6\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,3],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasButtons\"]]],null,{\"statements\":[[0,\"  \"],[7,\"footer\"],[11,\"class\",\"toolbox\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasCancel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"button-cancel\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"cancel\"],null]],[9],[0,\"\\n        Cancel\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"ifNotEmpty\",[[23,[\"mode\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[12,\"class\",[28,[\"button-confirm button-\",[27,\"camelize\",[[23,[\"mode\"]]],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"confirm\"],null]],[9],[0,\"\\n        \"],[1,[27,\"capitalize\",[[23,[\"mode\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/dialog.hbs"
    }
  });

  _exports.default = _default;
});