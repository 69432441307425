define("plans/routes/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        plan: this.get('store').findRecord('plan', params.id)
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('plan', models.plan);
    }
  });

  _exports.default = _default;
});