define("organization-settings/templates/adp-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kg8Q6nFh",
    "block": "{\"symbols\":[],\"statements\":[[5,\"wc-card\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"consented\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\" ADP is fully configured to work with WorkCompass. \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[0,\" Here is your secret code. \"],[10],[0,\"\\n    \"],[1,[27,\"wc-copy-icon\",null,[[\"text\"],[[23,[\"adpConfig\",\"sharedSecret\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"adpConfig\",\"requiresConsent\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\"],[9],[0,\" Consent required for WorkCompass to access your data on ADP \"],[10],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[28,[\"https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=4233b222-4d09-42e1-a20b-2e7bcba54dca&successUri=\",[21,\"successUri\"],\"?consented=true\"]]],[9],[0,\"Click here to give consent\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organization-settings/templates/adp-setup.hbs"
    }
  });

  _exports.default = _default;
});