define("ember-wc-360/adapters/application", ["exports", "ember-data", "ember-wc-360/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REPORT_DOMAIN = _environment.default.REPORT_DOMAIN;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: REPORT_DOMAIN,
    namespace: 'api/v1'
  });

  _exports.default = _default;
});