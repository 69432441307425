define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jYb4+iPL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"inDom\"]]],null,{\"statements\":[[4,\"ember-popper\",null,[[\"class\",\"ariaRole\",\"placement\",\"popperTarget\",\"renderInPlace\",\"popperContainer\",\"modifiers\",\"registerAPI\"],[[27,\"concat\",[\"dropdown-menu \",[23,[\"alignClass\"]],[27,\"if\",[[23,[\"isOpen\"]],\" show\"],null]],null],[23,[\"ariaRole\"]],[23,[\"popperPlacement\"]],[23,[\"toggleElement\"]],[23,[\"renderInPlace\"]],\"#ember-bootstrap-wormhole\",[23,[\"popperModifiers\"]],[27,\"action\",[[22,0,[]],\"registerPopperApi\"],null]]],{\"statements\":[[0,\"    \"],[14,1,[[27,\"hash\",null,[[\"item\",\"link-to\",\"divider\"],[[27,\"component\",[\"bs-dropdown/menu/item\"],null],[27,\"component\",[\"bs-dropdown/menu/link-to\"],null],[27,\"component\",[\"bs-dropdown/menu/divider\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});