define("plans/routes/team-bar/staff-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        staffMemberId: params.id
      });
    },
    setupController: function setupController(controller, models) {
      var staffMember = this.store.peekRecord('staff-member', models.staffMemberId);
      controller.set('staffMember', staffMember);
      this.controllerFor('team-bar').set('active', staffMember);
    }
  });

  _exports.default = _default;
});