define("design-system/templates/components/links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "knwSy/ca",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Links \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Links are lightly styled via the a element by default. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass links are used on the organization settings page to navigate between pages. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of a main link and a sub link. One has the class of wc-link and the other of wc-submenu-link.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <a href='/organization-settings' class='wc-side-navbar__link'>Organization settings</a>\\n    <a href='/organization-settings/performance-review-calendars' class='wc-side-navbar__submenu-link'>Performance review calendar</a>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"a\"],[11,\"href\",\"/organization-settings\"],[11,\"class\",\"wc-side-navbar__link\"],[9],[0,\"Organization settings\"],[10],[0,\"\\n\"],[7,\"a\"],[11,\"href\",\"/organization-settings/performance-review-calendars\"],[11,\"class\",\"wc-side-navbar__submenu-link\"],[9],[0,\"Performance review calendar\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/links.hbs"
    }
  });

  _exports.default = _default;
});