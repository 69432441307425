define("workcompass-components/components/wc-display-comments", ["exports", "workcompass-components/templates/components/wc-display-comments"], function (_exports, _wcDisplayComments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcDisplayComments.default
  });

  _exports.default = _default;
});