define("design-system/controllers/components/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ratingOptions: [{
      draftLabel: '0',
      score: 0
    }, {
      draftLabel: '1',
      score: 1
    }, {
      draftLabel: '2',
      score: 2
    }, {
      draftLabel: '3',
      score: 3
    }, {
      draftLabel: '4',
      score: 4
    }, {
      draftLabel: '5',
      score: 5
    }]
  });

  _exports.default = _default;
});