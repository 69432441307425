define("workcompass-components/templates/components/other-party-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TK0zQxcz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"other-party-rating rating-box row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"side-picture-wrapper\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"side-picture\"],[12,\"src\",[23,[\"model\",\"otherParty\",\"pictureURL\"]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"other-party-rating__wrapper\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"other-party-rating__name\"],[9],[0,\" \"],[1,[23,[\"model\",\"otherParty\",\"name\"]],false],[0,\": \"],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"other-party-rating__rating rating\"],[9],[0,\" \"],[1,[21,\"textForRating\"],false],[0,\" \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/other-party-rating.hbs"
    }
  });

  _exports.default = _default;
});