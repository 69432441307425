define("organization-settings/models/plan-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    planCount: _emberData.default.attr('number'),
    sectionTemplates: _emberData.default.hasMany('section-template'),
    ratingOptionTemplates: _emberData.default.hasMany('rating-option-templates'),
    ratingOptionsOrdering: ['draftScore'],
    orderedRatingOptionTemplates: Ember.computed.sort('ratingOptionTemplates', 'ratingOptionsOrdering'),
    displayableRatingOptionTemplates: Ember.computed.filterBy('orderedRatingOptionTemplates', 'isMarkedForArchival', false),
    sectionTemplatesOrdering: ['draftOrdinal:asc'],
    orderedSectionTemplates: Ember.computed.sort('sectionTemplates', 'sectionTemplatesOrdering'),
    displayableSectionTemplates: Ember.computed.filterBy('orderedSectionTemplates', 'isMarkedForArchival', false)
  });

  _exports.default = _default;
});