define("organization-settings/models/planning-phase", ["exports", "organization-settings/models/phase"], function (_exports, _phase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _phase.default.extend({
    startPosition: Ember.computed('startDate', function () {
      return this.get('startDate') * 3.1;
    }),
    width: Ember.computed('startMonth', 'endMonth', 'startDate', 'endDate', function () {
      if (this.get('startMonth') - this.get('endMonth') == 0) {
        return (this.get('endDate') - this.get('startDate')) * 3.2;
      } else {
        return (this.get('endMonth') - this.get('startMonth')) * 100 + (this.get('endDate') - this.get('startDate')) * 3.2;
      }
    }),
    label: 'Planning'
  });

  _exports.default = _default;
});