define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EckSD5QB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"inDom\"]]],null,{\"statements\":[[4,\"bs-tooltip/element\",null,[[\"id\",\"placement\",\"fade\",\"showHelp\",\"class\",\"renderInPlace\",\"popperTarget\",\"autoPlacement\",\"viewportElement\",\"viewportPadding\"],[[23,[\"overlayId\"]],[23,[\"placement\"]],[23,[\"fade\"]],[23,[\"showHelp\"]],[23,[\"class\"]],[23,[\"_renderInPlace\"]],[23,[\"triggerTargetElement\"]],[23,[\"autoPlacement\"]],[23,[\"viewportElement\"]],[23,[\"viewportPadding\"]]]],{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});