define("design-system/templates/components/inputs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fwBLXT4n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Inputs \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Input elements are used to gather information from users. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass inputs are used when adding a new organization goal or division goal. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of an input. It has the class of wc-input,\\n  it is possible to pass in a value.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    {{input class='wc-input' value='hello'}}\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[1,[27,\"input\",null,[[\"class\",\"value\"],[\"wc-input\",\"hello\"]]],false],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/inputs.hbs"
    }
  });

  _exports.default = _default;
});