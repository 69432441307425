define("organization-settings/controllers/third-parties", ["exports", "organization-settings/helpers/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var STATUSES = Object.freeze({
    SETUP: 'SETUP'
  });
  var SUPPORTED_THIRD_PARTIES = Object.freeze([{
    name: 'BambooHR',
    lastSyncText: 'Not setup yet',
    logo: '/assets/third-parties/bamboohr.png',
    status: STATUSES.SETUP,
    identity: {
      label: 'Subdomain',
      description: "The subdomain used to access your BambooHR's account (If you\n        access BambooHR at https://mycompany.bamboohr.com, then the subdomain is\n        \"mycompany\"). The subdomain must be alphanumeric."
    },
    secret: {
      label: 'API Key',
      description: "API Key generated in BambooHR. To generate an API key, users should\n        log in and click their name in the upper right-hand corner of any page to get to\n        the user context menu. If they have sufficient permissions, there will be an \"API\n        Keys\" option in that menu to go to the page."
    }
  }, {
    name: 'Paylocity',
    lastSyncText: 'Not setup yet',
    logo: '/assets/third-parties/paylocity.png',
    status: STATUSES.SETUP,
    clientIdentifier: {
      label: 'Company ID',
      description: "The Company ID is the identifier for your Paylocity company.\n        Please contact your Paylocity Account Manager to obtain this Company ID\n        if you do not already have it."
    },
    identity: {
      label: 'Client ID',
      description: "The Client ID is a unique identifier assigned to your Paylocity\n        integration. Please contact your Paylocity Account Manager to obtain this\n        Client ID if you do not already have it."
    },
    secret: {
      label: 'Client Secret',
      description: "The Client Secret is a confidential key used for secure\n        communication between your application and Paylocity's API. Please contact\n        your Paylocity Account Manager to obtain this Client Secret if you do not\n        already have it."
    }
  }]);

  var _default = Ember.Controller.extend({
    // Properties
    notice: null,
    thirdPartySelected: null,
    // Service
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    // Computed
    hasErrors: Ember.computed('saveSuccess', function () {
      return !this.saveSuccess && this.saveSuccess !== null;
    }),

    /**
     * Computed property to check if the selected third party requires a client identifier.
     * @param {String} thirdPartySelected.name - The name of the selected third party.
     * @return {Boolean} - True if the selected third party requires a client identifier,
     * false otherwise.
    */
    hasClientIdentifier: Ember.computed('thirdPartySelected.name', function () {
      var _this = this;

      var selectedThirdParty = SUPPORTED_THIRD_PARTIES.find(function (thirdParty) {
        return thirdParty.name === _this.get('thirdPartySelected.name');
      });
      return selectedThirdParty && !!selectedThirdParty.clientIdentifier;
    }),

    /**
     * List the available third parties for integration
     */
    thirdParties: Ember.computed('model', function () {
      var _this2 = this;

      var dateformat = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
      var thirdParties = SUPPORTED_THIRD_PARTIES.filter(function (third_party) {
        return _this2.session.currentUser["canSetup".concat(Ember.String.classify(third_party.name.toLowerCase()))];
      });

      if (!Ember.isEmpty(this.model)) {
        thirdParties = thirdParties.map(function (third_party) {
          var matching = _this2.model.find(function (t) {
            return t.name == third_party.name;
          });

          var thirdParty = _objectSpread({}, third_party);

          if (matching && !Ember.isEmpty(matching.syncAt)) {
            thirdParty.lastSyncText = 'Last sync at: ';
            thirdParty.lastSyncText += new Intl.DateTimeFormat('en-US', dateformat).format(matching.syncAt);
          }

          return thirdParty;
        });
      }

      return thirdParties;
    }),

    /**
     * Display success message for save
     */
    saved_success: function saved_success() {
      this.set('notice', _messages.NOTICE_MESSAGES.SAVED_SUCCESS);
    },

    /**
     * Dispaly error messsage for fail saving
     * @param   {HTMLEvent}   event 
     */
    saved_fail: function saved_fail(event) {
      // Stop propagation to prevent
      // dialog to close on error
      event.stopPropagation();
      this.set('notice', _messages.NOTICE_MESSAGES.HAS_ERRORS);
    },
    actions: {
      /**
       * Check identity field validity
       * @param {HTMLEvent} event 
       */
      validateIdentity: function validateIdentity(event) {
        event.target.reportValidity();
      },

      /**
       * Open dialog if third party in the
       * setup stage
       * @param   {Object}  thirdparty
       */
      setup: function setup(thirdparty) {
        if (thirdparty.status !== STATUSES.SETUP) {
          return;
        }

        this.set('thirdPartySelected', thirdparty);
      },

      /**
       * Action trigger when dialog being closed
       */
      onDialogClose: function onDialogClose() {
        this.set('saveSuccess', null);
        this.set('thirdPartySelected', null);
      },

      /**
       * Action to save third party credentials
       * @param {HTMLEvent} event
       */
      saveSetting: function saveSetting(event) {
        var _this3 = this;

        // Reset flag
        this.set('saveSuccess', null);
        event.preventDefault();
        var dialog = event.target.closest('dialog');
        var form = dialog.querySelector('form');

        if (!form.reportValidity()) {
          this.saved_fail(event);
          return;
        }

        var formData = new FormData(form);
        var formDataJSON = {};
        formData.forEach(function (value, key) {
          if (!(key === 'clientIdentifier' && !_this3.hasClientIdentifier)) {
            formDataJSON[key] = value;
          }
        });
        return this.store.createRecord('thirdParty', formDataJSON).save().then(function () {
          _this3.saved_success();
        }).catch(function () {
          _this3.saved_fail(event);
        });
      }
    }
  });

  _exports.default = _default;
});