define("workcompass-components/templates/components/user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2zMYw6HB",
    "block": "{\"symbols\":[\"@pictureURL\",\"@name\",\"&default\"],\"statements\":[[4,\"if\",[[27,\"ifNotEmpty\",[[23,[\"pictureURL\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[22,1,[]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"span\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/user-card.hbs"
    }
  });

  _exports.default = _default;
});