define("chat/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Services   
    store: Ember.inject.service(),

    /**
     * Getter for retrieving the custom mascot
     * from the store if existing
     */
    chatMascot: Ember.computed('', function () {
      var mascot = this.store.peekRecord('setting', 'chat-mascot');

      if (mascot) {
        return mascot.value;
      }

      return '/assets/images/blinky-chat-bot.gif';
    })
  });

  _exports.default = _default;
});