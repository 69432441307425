define("plans/services/label-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    label: function label(item) {
      switch ("".concat(item.get('approved?'), "-").concat(item.get('removed?'))) {
        case 'true-true':
          return 'removal requested';

        case 'true-false':
          return 'approved';

        case 'false-true':
          return 'deleted';

        case 'false-false':
          return 'not yet approved';

        default:
          return 'wrong';
      }
    }
  });

  _exports.default = _default;
});