define("organization-settings/routes/authentication-options/azure-oauth2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      return this.get('store').findRecord('authentication-option', 'azure_oauth2');
    }
  });

  _exports.default = _default;
});