define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bjLq6vmv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"hasLabel\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"labelComponent\"]]],[[\"labelClass\"],[[23,[\"horizontalLabelGridClass\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"horizontalInputGridClass\"]]]],[9],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"errorsComponent\"]]],null],false],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"helpTextComponent\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"horizontalInputGridClass\"],\" \",[21,\"horizontalInputOffsetGridClass\"]]]],[9],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"errorsComponent\"]]],null],false],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"helpTextComponent\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal.hbs"
    }
  });

  _exports.default = _default;
});