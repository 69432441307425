define("design-system/templates/components/labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3w7zLxsw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Labels \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Labels are used to describe inputs, select menus, textareas, radio buttons, and checkboxes. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" In WorkCompass labels are used in the card details component. \"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" Example 1 shows a simple example of a label. It has the class of wc-label. \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <label class='wc-label'> Notes: </label>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"label\"],[11,\"class\",\"wc-label\"],[9],[0,\" Notes: \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/labels.hbs"
    }
  });

  _exports.default = _default;
});