enifed("ember-template-compiler/lib/plugins/transform-angle-bracket-components", ["exports"], function (_exports) {
  "use strict";

  _exports.default = transformAngleBracketComponents;

  function transformAngleBracketComponents()
  /* env */
  {
    return {
      name: 'transform-angle-bracket-components',
      visitor: {
        ComponentNode: function (node) {
          node.tag = "<" + node.tag + ">";
        }
      }
    };
  }
});