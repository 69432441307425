define("workcompass-components/templates/components/wc-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "edj8bTJS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"wc-notice__content justify-content-center\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"wc-notice__icon\"],[12,\"src\",[21,\"iconPath\"]],[9],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"wc-notice__message\"],[9],[14,1],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/wc-notice.hbs"
    }
  });

  _exports.default = _default;
});