define("workcompass-components/templates/components/development-need-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YU2XTzEI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"wc-card\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"wc-card-header\",null,[[\"number\",\"item\"],[[23,[\"number\"]],[23,[\"developmentNeed\"]]]]],false],[0,\"\\n\"],[4,\"wc-card-body\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-1 card__first-col\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-11 pl-0 card__comment-input\"],[9],[0,\"\\n        \"],[1,[27,\"comment-input\",null,[[\"commentable\",\"isDisabled\",\"placeholderText\"],[[23,[\"developmentNeed\"]],[23,[\"isDisabled\"]],[27,\"concat\",[\"Start typing to discuss this rating...\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"wc-display-comments\",null,[[\"anyComments\",\"allComments\"],[[23,[\"anyDevelopmentNeedComments\"]],[23,[\"developmentNeed\",\"orderedComments\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/development-need-card.hbs"
    }
  });

  _exports.default = _default;
});