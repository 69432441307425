define("workcompass-components/components/badge-menu", ["exports", "workcompass-components/components/trigger-dropdown", "workcompass-components/templates/components/badge-menu"], function (_exports, _triggerDropdown, _badgeMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _triggerDropdown.default.extend({
    // Native
    classNames: ['badge-menu'],
    layout: _badgeMenu.default,
    // Computed

    /**
     * Get the list options for the menu
     * based on the display, value, subdisplay
     * key provided.
     */
    options: Ember.computed('items', 'display', 'value', 'subdisplay', function () {
      var displayKey = this.display || 'display';
      var valueKey = this.value || 'value';
      var subdisplayKey = this.subdisplay || 'subdisplay';
      return this.items.map(function (item) {
        return {
          display: item.hasOwnProperty(displayKey) ? item[displayKey] : '',
          value: item.hasOwnProperty(valueKey) ? item[valueKey] : '',
          subdisplay: item.hasOwnProperty(subdisplayKey) ? item[subdisplayKey] : ''
        };
      });
    }),

    /**
     * Flag to indicate if separator should be display
     *
     * has add but no action and no record message => false
     * has add and actions => true
     * no add but actions => false
     * no add and no actions but has norecord message => false
     * add and no actions but has no record message => true
     */
    showSeparator: Ember.computed('options', 'noRecords', function () {
      return !Ember.isEmpty(this.addText) && !this.options.length && !Ember.isEmpty(this.noRecords) || !Ember.isEmpty(this.addText) && this.options.length;
    }),
    // Method

    /**
     * Close menu
     * Extends from parent to close detached menu
     * @param {DOM Event} evt
     */
    close: function close(evt) {
      this._super.apply(this, arguments);

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this.ownerElement.querySelector(".js-detached-".concat(this.element.id))) {
        evt.stopPropagation();
        this.set('isExpanded', false);
        this.ownerElement.querySelector(".js-detached-".concat(this.element.id)).remove();
      }
    },
    actions: {
      /**
       * Trigger when an item is selected
       * @param {string}  value   Value to passthrough to the parent
       */
      select: function select(value) {
        if (typeof this.onSelect === 'function') {
          this.onSelect(value);
        }
      },

      /**
       * Expand/collapse the menu
       * @param {DOM Event}   evt   Event send by DOM
       */
      expand: function expand(evt) {
        var detachedClass = "js-detached-".concat(this.element.id);
        evt.preventDefault();
        this.toogle(); // Put into a later to ensure the menu
        // is set before performing action.

        Ember.run.later(this, function () {
          var menu = evt.target.closest('.ember-wc-core.badge-menu').querySelector('section');

          if (menu) {
            menu.style.display = ''; // Hacky way to have the menu stick to the badge
            // due to overflow scrolling for the target container
            // that cannot be compensated by CSS at this time

            if (this.detach === 'true' // Ensure not already happended
            && !this.ownerElement.querySelector(".".concat(detachedClass))) {
              menu.classList.add('ember-wc-core');
              menu.classList.add('badge-menu');
              menu.classList.add('js-detached');
              menu.classList.add(detachedClass);
              var ownerBounderies = this.ownerElement.getBoundingClientRect();
              var scale = ownerBounderies.width / this.ownerElement.offsetWidth;
              var elementBounderies = menu.getBoundingClientRect();
              this.ownerElement.appendChild(menu);
              menu.style.position = 'fixed';
              menu.style.top = "".concat((elementBounderies.y - ownerBounderies.y - window.scrollY) * (1 / scale), "px");
              menu.style.left = "".concat((elementBounderies.x - ownerBounderies.x - window.scrollX) * (1 / scale), "px");
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});