define("workcompass-components/components/wc-card", ["exports", "workcompass-components/templates/components/wc-card"], function (_exports, _wcCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcCard.default,
    tagName: 'section',
    classNames: ['wc-card']
  });

  _exports.default = _default;
});