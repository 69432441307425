define("design-system/templates/components/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4qzbkDJ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\" Select \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"\\n  A selectable menu list from which a user can make a single selection. Typically they are used when there are more than four possible options.\\n\"],[10],[0,\"\\n\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  Example 1 shows a simple example of a rating dropdown select menu. An array of orderedRatingOptions is passed in which have both a label and a score.\\n\"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    {{wc-rating-dropdown orderedRatingOptions=orderedRatingOptions}}\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[1,[27,\"wc-rating-dropdown\",null,[[\"orderedRatingOptions\"],[[23,[\"ratingOptions\"]]]]],false],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/select.hbs"
    }
  });

  _exports.default = _default;
});