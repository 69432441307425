define("plans/components/x-section", ["exports", "plans/templates/components/x-section"], function (_exports, _xSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xSection.default,
    isQuestion: Ember.computed.equal('section.type', 'Question'),
    isNotQuestion: Ember.computed.not('isQuestion'),
    itemsToDisplay: Ember.computed.notEmpty('section.approvedItems'),
    sectionClass: Ember.computed(function () {
      var dasherize = function dasherize(type) {
        return type.replace(/[A-Z]/g, function (c, i) {
          return (i !== 0 ? '-' : '') + c.toLowerCase();
        });
      };

      return "".concat(dasherize(this.get('section.type')), "-section");
    })
  });

  _exports.default = _default;
});