define("workcompass-components/templates/components/charts/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7zpwTcbw",
    "block": "{\"symbols\":[\"data\",\"line\",\"@title\",\"&default\"],\"statements\":[[7,\"dt\"],[9],[1,[22,3,[]],false],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"verticalLines\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"graph-vl\"],[12,\"style\",[28,[\"left:\",[22,2,[\"left\"]],\";\"]]],[12,\"data-label\",[22,2,[\"label\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"each\",[[22,0,[\"datalist\"]]],null,{\"statements\":[[0,\"  \"],[7,\"dd\"],[12,\"class\",[28,[\"percentage percentage-\",[22,1,[\"percent\"]],\" \",[22,1,[\"classes\"]]]]],[12,\"title\",[28,[[22,1,[\"display\"]],\" - \",[22,1,[\"value\"]]]]],[12,\"data-label\",[22,1,[\"display\"]]],[12,\"data-value\",[22,1,[\"value\"]]],[9],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/charts/bar.hbs"
    }
  });

  _exports.default = _default;
});