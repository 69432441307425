define("workcompass-components/templates/components/priority-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rq3Staut",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fixed-bottom priority-task\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col d-flex align-items-center priority-task__content\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"priority-task__icon\"],[9],[7,\"img\"],[11,\"src\",\"/assets/images/alert-icon.png\"],[9],[10],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"priority-task__separator\"],[9],[0,\"|\"],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"priority-task__message\"],[9],[1,[21,\"messageText\"],false],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"priority-task__button ml-auto\"],[9],[1,[21,\"buttonText\"],false],[3,\"action\",[[22,0,[]],\"review\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "workcompass-components/templates/components/priority-task.hbs"
    }
  });

  _exports.default = _default;
});