define("workcompass-components/components/form-controls/selection", ["exports", "workcompass-components/components/form-controls/control"], function (_exports, _control) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _control.default.extend({
    sortedOptions: Ember.computed('data', 'display', function () {
      var self = this;
      return (this.data || []).sort(function compareSortDisplay(a, b) {
        if (a[self.display] < b[self.display]) {
          return -1;
        }

        if (a[self.display] > b[self.display]) {
          return 1;
        }

        return 0;
      });
    })
  });

  _exports.default = _default;
});