define("design-system/templates/components/typography", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B9ZZiz19",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[0,\" Typography \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"\\n  Typography is the style and appearance of printed matter.\\n\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"\\n  The main font used throughout the WorkCompass app is Roboto.\\n\"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" Headers \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\" Headers represent a container for introductory content. \"],[10],[0,\"\\n\\n\"],[7,\"h2\"],[9],[0,\" In WorkCompass \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  In WorkCompass an orange main header is used on the plan page, the template page the org and div goals pages.\\n  A smaller header is used on cards.\\n\"],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\" Examples \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Example 1 \"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\" Example 1 shows an example of the orange title header and the card header. \"],[10],[0,\"\\n\"],[7,\"h4\"],[9],[0,\" Here is the code: \"],[10],[0,\"\\n\"],[7,\"pre\"],[9],[0,\"  \"],[7,\"code\"],[9],[0,\"\\n    \"],[1,\"\\n    <h1 class='col wc-header'>Goals</h1>\\n    \",false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\"],[7,\"h1\"],[11,\"class\",\"col wc-header\"],[9],[0,\"Goals\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "design-system/templates/components/typography.hbs"
    }
  });

  _exports.default = _default;
});