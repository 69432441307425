define("chat/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OURJ8iT9",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"chat-box\",null,[[\"mascot\",\"context\",\"startingResponsePartUUID\",\"hideCloseButton\",\"hideCoachingAssistant\",\"saveCoachingFeedback\",\"editCoachingFeedback\"],[[23,[\"chatMascot\"]],[27,\"hash\",null,[[\"staffMemberName\",\"currentUserName\",\"milestoneDescription\",\"currentUserPictureURL\",\"hasNoManager\",\"isStaffMember\",\"planId\"],[[23,[\"model\",\"staffMemberName\"]],[23,[\"model\",\"currentUserName\"]],[23,[\"model\",\"milestoneDescription\"]],[23,[\"model\",\"currentUserPictureURL\"]],[23,[\"model\",\"hasNoManager\"]],[23,[\"model\",\"isStaffMember\"]],[23,[\"model\",\"planId\"]]]]],[23,[\"model\",\"startingResponsePartUUID\"]],[23,[\"model\",\"hideCloseButton\"]],[27,\"action\",[[22,0,[]],[23,[\"model\",\"hideCoachingAssistant\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"model\",\"saveCoachingFeedback\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"model\",\"editCoachingFeedback\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "chat/templates/application.hbs"
    }
  });

  _exports.default = _default;
});