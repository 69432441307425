define("plans/models/progress-update", ["exports", "ember-data", "plans/models/progress-update-text"], function (_exports, _emberData, _progressUpdateText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _progressUpdateText.default.extend({
    user: _emberData.default.belongsTo('staff-member'),
    milestone: _emberData.default.belongsTo('milestone'),
    progressUpdatedAt: _emberData.default.attr('date'),
    commentType: 'Progress update',
    progressValue: _emberData.default.attr('number'),
    createdAt: Ember.computed.alias('progressUpdatedAt'),
    deletedAt: _emberData.default.attr('date'),
    label: 'Progress update',
    textToDisplay: Ember.computed('progressValue', function () {
      var progressValue = this.get('progressValue');
      return this.progressUpdateText(progressValue, true);
    })
  });

  _exports.default = _default;
});