define("plans/components/printable-goal-section", ["exports", "plans/templates/components/printable-goal-section"], function (_exports, _printableGoalSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _printableGoalSection.default,
    tagName: 'section',
    classNames: ['printable-section', 'printable-goal-section']
  });

  _exports.default = _default;
});