define("organization-settings/helpers/can-manage", ["exports", "organization-settings/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasPermission = hasPermission;
  _exports.canManage = canManage;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Constructs the permission name based on the provided route path.
   *
   * @param   {String} path - The route path or name for which to determine permissions.
   * @returns {String} - The constructed permission name corresponding to the provided path.
   */
  function permissionFrom(path) {
    var base = path.replace("".concat(_environment.default.modulePrefix, "."), '');
    var pathParts = base.split('/');
    var suffix = pathParts.map(function (part) {
      return Ember.String.classify(part);
    }).join('');
    return "canManage".concat(suffix);
  }
  /**
   * Determines whether the specified permission is defined for the provided user.
   *
   * The route path can be specified either as a simple path name (e.g., 'item') or as a route name
   * composed of multiple segments separated by dots (e.g., 'organization-settings.admin/item').
   *
   * @param   {String}  path - The route path or name to check permissions for.
   * @param   {User}    user - The user model for which to check permission definition.
   * @returns {Boolean} - True if the permission is defined for the user, otherwise false.
   */


  function hasPermission(path, user) {
    return permissionFrom(path) in user;
  }
  /**
   * Determines whether the user is authorized to manage the entity specified by the path.
   *
   * The route path can be specified either as a simple path name (e.g., 'item') or as a route name
   * composed of multiple segments separated by dots (e.g., 'organization-settings.admin/item').
   *
   * @param   {Array<String, User>} params - An array containing the route path and the user model.
   * @returns {Boolean} - True if the user is authorized to manage the entity, otherwise false.
   */


  function canManage(params
  /*, hash*/
  ) {
    var _params = _slicedToArray(params, 2),
        path = _params[0],
        user = _params[1];

    var permission = permissionFrom(path);
    return hasPermission(path, user) && user[permission];
  }

  var _default = Ember.Helper.helper(canManage);

  _exports.default = _default;
});