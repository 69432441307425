define("ember-wc-360/templates/programs-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YY5gHEvR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"messagebox warning\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"This 360° feedback report does not exist or currently unavailable.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-wc-360/templates/programs-error.hbs"
    }
  });

  _exports.default = _default;
});