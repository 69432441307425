define("plans/components/question-card", ["exports", "plans/templates/components/question-card"], function (_exports, _questionCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _questionCard.default,
    anyQuestionComments: Ember.computed.notEmpty('question.comments')
  });

  _exports.default = _default;
});