define("workcompass-components/components/wc-dropdown", ["exports", "workcompass-components/templates/components/wc-dropdown"], function (_exports, _wcDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _wcDropdown.default,
    searchEnabled: false,
    options: [],
    choice: Ember.computed('options', function () {
      var options = this.get('options');

      if (!options) {
        return null;
      }

      return options[0];
    })
  });

  _exports.default = _default;
});